<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName" >
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">

        <el-collapse-item name="1">
          <template slot="title">
            <h2>客戶資料查詢</h2>
          </template>

        <el-row :gutter="20">
          <el-col :span="7">
              <el-input placeholder="客戶帳號" v-model="qClientUserId">
                <template slot="prepend">客戶帳號</template>
              </el-input>
          </el-col>
          <el-col :span="8">
              <el-input placeholder="訂單號" v-model="qClientOrderId">
                <template slot="prepend">訂單號</template>
              </el-input>
          </el-col>
          <el-col :span="9">
            排序<el-radio-group v-model="qOrderType" size="medium" @change="query">
              <el-radio-button :label="orderType_1"></el-radio-button>
              <el-radio-button :label="orderType_2"></el-radio-button>
              <el-radio-button :label="orderType_3"></el-radio-button>
            </el-radio-group>      
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="7">
              <el-input placeholder="客戶電話" v-model="qPhone">
                <template slot="prepend">客戶電話</template>
              </el-input>
          </el-col>      
          <el-col :span="8">
              <el-input placeholder="車號" v-model="qCarNo">
                <template slot="prepend">車號</template>
              </el-input>
          </el-col>
          <el-col :span="9">
          狀態&nbsp;&nbsp;&nbsp;<el-select v-model="qFlow" clearable placeholder="">
              <el-option
                v-for="item in flows"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="9">
          訂單管道&nbsp;&nbsp;&nbsp;<el-select v-model="qClientType" clearable placeholder="">
              <el-option
                v-for="item in clientTypes"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        </el-collapse-item>
        <el-collapse-item name="2">

          <template slot="title">
            <h2>時間與店家查詢</h2>
          </template>

        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
          實際租車日期 <el-date-picker
              v-model="qRealStartDateStart"
              type="date"
              placeholder="實際租車日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qRealStartDateEnd"
              type="date"
              placeholder="實際租車日期">
            </el-date-picker>
          </el-col>
          <el-col :span="1">             
          </el-col>
          <el-col :span="8">
            長租與短租&nbsp;&nbsp;&nbsp;<el-radio-group v-model="qRentDateType" size="medium" @change="query">
                <el-radio-button :label="qRentDateType_1" ></el-radio-button>
                <el-radio-button :label="qRentDateType_2" ></el-radio-button>
                <el-radio-button :label="qRentDateType_3" ></el-radio-button>
              </el-radio-group>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
            實際還車日期 <el-date-picker
              v-model="qRealEndDateStart"
              type="date"
              placeholder="實際還車日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qRealEndDateEnd"
              type="date"
              placeholder="實際還車日期">
            </el-date-picker>
          </el-col>
          <el-col :span="1">               
          </el-col>
          <el-col :span="6" >
            <el-input placeholder="優惠碼" type="hidden" >
                <!-- <template slot="prepend" type="hidden">優惠碼</template> -->
              </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
            預計取車日期 <el-date-picker
              v-model="qSDate"
              type="date"
              placeholder="預計取車日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qEDate"
              type="date"
              placeholder="預計取車日期">
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
            預計還車日期 <el-date-picker
              v-model="qEDateStart"
              type="date"
              placeholder="預計還車日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qEDateEnd"
              type="date"
              placeholder="預計還車日期">
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
            付款日期區間 <el-date-picker
              v-model="qPayDateStart"
              type="date"
              placeholder="付款日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qPayDateEnd"
              type="date"
              placeholder="付款日期">
            </el-date-picker>
          </el-col>
        </el-row>
        
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
            訂單成立日期區間 <el-date-picker
              v-model="qCreateDateStart"
              type="date"
              placeholder="成立日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qCreateDateEnd"
              type="date"
              placeholder="成立日期">
            </el-date-picker>
          </el-col>
        </el-row>
        
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="15">
            訂單取消日期區間 <el-date-picker
              v-model="qCancelDateStart"
              type="date"
              placeholder="取消日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qCancelDateEnd"
              type="date"
              placeholder="取消日期">
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">  
          <el-col :span="6">
            <div class="grid-content bg-purple"></div>
            地區&nbsp;&nbsp;&nbsp;<el-select v-model="qAreaId" clearable placeholder="">
            <el-option width=120 v-for="item in areas" :key="item.code" :label="item.disName" :value="item.code"></el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple"></div>
            店家狀態&nbsp;&nbsp;&nbsp;<el-select v-model="qStoreStatus" clearable placeholder="">
            <el-option width=120 v-for="item in storeStatusList" :key="item.code" :label="item.disName" :value="item.code"></el-option>
            </el-select>
          </el-col>  
          <el-col :span="6">          
            出租店家&nbsp;&nbsp;<el-select v-model="qStoreId" filterable>
              <el-option 
                v-for="item in stores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                v-show="displayStore(item)"
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            還車店家&nbsp;&nbsp;<el-select v-model="qReturnStoreId">
              <el-option 
                v-for="item in returnStoreId"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                v-show="displayStore(item)">
              </el-option>            
            </el-select>
          </el-col>
        </el-row>
      </el-collapse-item>

        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="8">
            <el-button type="primary" @click="query()">查詢</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
            <el-button type="primary" @click="downloadXls()">下載excel</el-button>            
          </el-col>
        </el-row>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column prop="clientOrderId" width="160px" label="訂單號"></el-table-column>
        <el-table-column prop="carNo"  width="100px" label="車號"></el-table-column>
        <el-table-column prop="clientUserDisName" width="100px" label="客戶">  </el-table-column>
        <el-table-column prop="clientUserId" width="100px" label="客戶帳號">  </el-table-column>
        <el-table-column prop="storeDisName" width="160px" label="店家">  </el-table-column>
        <el-table-column prop="phone" width="110px" label="電話">  </el-table-column>
        <el-table-column prop="sDate" width="160px" label="取車時間">  </el-table-column>
        <el-table-column prop="flow" width="95px" label="狀態">
          <template slot-scope="scope">
            <el-tag :type="scope.row.flowType">
            {{scope.row.flow}}
          </el-tag>
          </template> 
        </el-table-column>
        <el-table-column   prop="price" align="right" label="金額">  </el-table-column>
        <el-table-column   prop="reserveNote" width="160px" label="員工備註">  </el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>

  <el-dialog title=" " :close-on-click-modal="false" :visible.sync="dialogFormVisible" font-size="150%" width="90%" center>
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">訂單明細
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-col :span="20">{{form.clientOrderId}}</el-col>
            <el-col :span="4">
              <el-button v-show="form.isShowTrafficOp" type="danger" @click="showTrafficTicketForm()">罰單轉責</el-button>
            </el-col>
          </td></tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> 
          <el-col :span="20">{{form.clientUserDisName}}</el-col>
          <el-col :span="4">
            <el-button v-show="form.ban != 'T'" type="danger" @click="banUser()">加入黑名單</el-button>
            <el-button v-show="form.ban == 'T'" @click="unBanUser()">解除黑名單</el-button>
          </el-col>
            </td></tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientPhone}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">出生年月日:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientBirthday}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶帳號:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientUserId}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">Email:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientEmail}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單管道:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientType | filter_clientType}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車類:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.prodPriceDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車型:</td>
          <td style="border-style:solid; border-width:1px;">{{form.catDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車號:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.carNo}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.sDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單還車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.eDate}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.realStartDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際還車時間:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.realEndDate}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{form.rentDateTypeDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購保障:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusInsuranceDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取車預付金額:</td>
            <td style="border-style:solid; border-width:1px;">{{form.beforePrice}}</td>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">押金金額:</td>
            <td style="border-style:solid; border-width:1px;">{{form.tenancyDeposit}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂金:</td>
          <td style="border-style:solid; border-width:1px;">{{form.deposit}}({{form.depositPayMethod}})</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單總金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.price}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購金額:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusPrice}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">尾款:</td>
          <td style="border-style:solid; border-width:1px;">{{form.prePrice}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">折扣碼:</td>
          <td style="border-style:solid; border-width:1px;">{{form.promotionCode}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">折扣金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.discount}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取車電壓:</td>
          <td style="border-style:solid; border-width:1px;">{{form.fromVoltage}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">還車電壓:</td>
          <td style="border-style:solid; border-width:1px;">{{form.toVoltage}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取車電量(SoC):</td>
          <td style="border-style:solid; border-width:1px;">{{form.fromSoc}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">還車電量(Soc):</td>
          <td style="border-style:solid; border-width:1px;"> {{form.toSoc}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">駕照類型:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.motoLicenseType}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientNote}}</td></tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">員工訂單備註:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">
              {{form.reserveNote}}
              <el-button type="primary" @click="editReserveNote = !editReserveNote" size="medium">編輯備註</el-button>
            </td>
            
          </tr>
          <tr v-show="editReserveNote">
            <td colspan="4" class="editReserveNote" style="border-style:solid; border-width:1px;">
              <el-row>
                <el-col :span="8">
                  <el-input v-model="form_.reserveNote" type=""></el-input>
                </el-col>
                <el-button class="editReserveNoteSureButton" type="primary" size="medium" @click="confirmEditReserveNote('reserveNote')">確定編輯</el-button>
              </el-row>
            </td>
          </tr>
          <tr v-if="form.flow=='1000'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取消原因:</td>
          <td colspan="3">{{form.cancelNote}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">出發里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.startDistance}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">結束里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.endDistance}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶喜好:</td>
          <td style="border-style:solid; border-width:1px;">{{form.adminNote}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">安全帽</td>
          <td style="border-style:solid; border-width:1px;">3/4：{{form.accessoriesHelmetFull}}頂，半罩：{{form.accessoriesHelmet}}頂</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">雨衣：</td>
          <td style="border-style:solid; border-width:1px;">{{form.accessoriesRaincoat}}件</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票號碼：</td>
          <td style="border-style:solid; border-width:1px;">{{form.invoiceId}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票種類：</td>
          <td style="border-style:solid; border-width:1px;">{{form.invoiceType}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票統編:</td>
          <td style="border-style:solid; border-width:1px;">{{form.vatNo}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票抬頭：</td>
          <td style="border-style:solid; border-width:1px;">{{form.vatCompanyTitle}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">得知我們:</td>
          <td style="border-style:solid; border-width:1px;">{{form.getFrom | filter_getFrom}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">使用目的:</td>
          <td style="border-style:solid; border-width:1px;">{{form.usePurpose | filter_usePurpose}}</td></tr>

          <tr><td colspan="4" style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶證件:</td></tr>
          <tr style="border-style:solid; border-width:5px; ">
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">歷史紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="(item, key) in clientOrderTrans" :key="key">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">評分日期:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">
              <div style='font-size:14px;' v-for="history in kycHistory" :key="history.rowId">
                <el-tag  :type="history.statusType">
                    {{history.status}}
                </el-tag>
                時間:{{history.updateDate}}評分:{{history.resultRank}}
                <el-tag  v-show="userInfo.auth.userType === '0'" @click="history.show = !history.show,$forceUpdate()">明細</el-tag>
                <div v-show="history.show"><h3>交通判決</h3><span v-for="(v,idx) in history.vs" :key="idx">{{v.disName}} = {{v.value}}<br/></span></div>
                <div v-show="history.show"><h3>司法判決</h3><span v-for="(c,idx) in history.cs" :key="idx">{{c.disName}} = {{c.value}}<br/></span></div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">KYC評分:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">
              <el-row style="display: flex;">
                <el-col :span="24" style="display: flex;">
                  <div class="grid-content bg-purple">
                    <span style="color:black;">{{ratio}}</span>
                    <span :style="historyLabelColor">{{historyLabel}}</span>
                  </div>
                </el-col>
              </el-row>
            </td>
            </tr>
        </tbody>
      </table>
      <!-- 簽名檔手風琴 -->
      <el-collapse v-model="transName" v-show="this.clientOrderSignature != null" style="margin:10px;">
        <el-collapse-item name="1">
          <template slot="title">
            <p style="margin-left:10px; font-size:25px; font-weight:bold;">簽名檔</p>
          </template>
          <img :src="clientOrderSignature" style="height:400px;">
        </el-collapse-item>
      </el-collapse>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="downloadPdf()" style="font-size:150%">匯出PDF</el-button>
    <el-button @click="dialogFormVisible = false, transName = '0'" style="font-size:150%">退  出</el-button>
    <!-- <el-button type="primary" @click="confirmEditStore">確 定</el-button> -->
  </div>
</el-dialog>
<el-dialog title="加入黑名單後，該顧客帳號將被停權無法於所有門市租借車輛" :close-on-click-modal="false" :visible.sync="banAlert" font-size="150%">
  <el-form style=" border-spacing:0px; font-size:200%; text-align:center; ">
    <el-form-item label="備註" :label-width="formLabelWidth">
    <el-input placeholder="備註" v-model="form.banNote"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="banAlert = false">取消</el-button>
    <el-button @click="confirmBanClientUser()" type="primary">確定</el-button>
  </div>
</el-dialog>
<el-dialog title="罰單" :close-on-click-modal="false" :visible.sync="trafficTicketFormVisable" font-size="150%">
  <el-form style=" border-spacing:0px; font-size:200%;">
    <el-form-item label="訂單編號" :label-width="formLabelWidth">
      {{trafficTicketForm.clientOrderId}}
    </el-form-item>
    <el-form-item label="罰單單號" :label-width="formLabelWidth">
      <el-input v-model="trafficTicketForm.ticketId" autocomplete="off" @change="checkTrafficTicketId"></el-input>
    </el-form-item>
    <el-form-item label="罰單金額" :label-width="formLabelWidth">
      <el-input v-model="trafficTicketForm.ticketPrice" type="number" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="扣款金額" :label-width="formLabelWidth">
      {{(trafficTicketForm.ticketPrice * 1.1).toFixed(0)}}
      <!-- <el-input v-model="trafficTicketForm.deductionPrice" type="number" autocomplete="off"></el-input> -->
    </el-form-item>
    <el-form-item label="罰單狀態" :label-width="formLabelWidth">
      <el-select v-model="trafficTicketForm.ticketStatus" disabled>
        <el-option value="0" label="待處理"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="違規日期" :label-width="formLabelWidth">
      <el-date-picker v-model="trafficTicketForm.ticketDate" @change="checkTicketDate" autocomplete="off"></el-date-picker>
    </el-form-item>
    <el-form-item label="郵遞區號" :label-width="formLabelWidth">
      <el-input v-model="trafficTicketForm.zipCode" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="通訊地址" :label-width="formLabelWidth">
      <el-input v-model="trafficTicketForm.address" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="應到案日期" :label-width="formLabelWidth">
      <el-date-picker v-model="trafficTicketForm.arrivalDate" autocomplete="off"></el-date-picker>
    </el-form-item>
    <el-form-item label="照片上傳" :label-width="formLabelWidth" >
      <input type="file" accept="image/*" @change="fileChange">
    </el-form-item>
    <el-form-item label="備註" :label-width="formLabelWidth">
      <el-input placeholder="備註" v-model="trafficTicketForm.note"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="trafficTicketFormVisable = false">取消</el-button>
    <el-button @click="confirmCreateTrafficTicket()" type="primary">確定</el-button>
  </div>
</el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      pic1:null,pic2:null,pic3:null,pic4:null,
      pageTotal:0,
      pageCurrent:1,
      qClientUserId:'',
      qClientOrderId:'',
      qSDate:'',
      qEDate:'',
      qEDateStart:'',qEDateEnd:'',
      qFlow:'',
      qPhone:'',
      qCarNo:'',
      qStoreStatus:'',
      qAreaId:'',
      qStoreId:'',
      qReturnStoreId:'',
      qRealStartDateStart:'',qRealStartDateEnd:'',
      qRealEndDateStart:'',qRealEndDateEnd:'',
      qPayDateStart:'',qPayDateEnd:'',
      qCreateDateStart:'',qCreateDateEnd:'',
      qCancelDateStart:'',qCancelDateEnd:'',
      qRentDateType:'全部',qRentDateType_1:'短租',qRentDateType_2:'長租',qRentDateType_3:'全部',
      qOrderType:'訂單成立⇡',orderType_1:'訂單成立⇡',orderType_2:'取車時間⇡',orderType_3:'訂單狀態⇡',
      qClientType: '',
      tableData:[],
      historyFlow:[],
      historyBooking:[],
      clientOrderTrans: [], // 支付紀錄
      clientOrderSignature: '', // 簽名檔路徑
      dialogFormVisible: false,
      fullscreenLoading: false,
      form: {
        deposit:0,
        depositPayMethod: '',
        promotionCode: '',
        discount: 0,
        accessoriesHelmetFull:0,
        accessoriesHelmet:0,
        accessoriesRaincoat:0,
        banNote:'',
        tenancyDeposit:''
      },
      form_:{
        reserveNote:''
      },
      formLabelWidth: '120px',
      cats: [],
      areas: [],
      storeStatusList: [
        {code:'', disName:'全部'},
        {code:'1', disName:'營業中'},
        {code:'0', disName:'停業'}
      ],
      stores: [], // 出租分店
      prodPrices: [],
      catsMap: [],
      storesMap: [],
      selectStores: [],
      returnStoreId: [], // 還車分店
      flows: [], // 狀態
      clientTypes: [], // 訂單管道
      activeName: '1', // 搜尋手風琴預設
      transName: '0', // 簽名檔手風琴預設
      historyLabel: '',
      historyLabelColor: '',
      kycHistory: [],
      ratio: '尚未驗證過',
      statusMap: {
        '0': '等待驗證',
        '1': '驗證完成',
        '2': '驗證失敗'
      },
      statusTypeMap: {
        '0': 'warning',
        '1': 'success',
        '2': 'danger'
      },
      banAlert: false,
      editReserveNote:false,
      trafficTicketFormVisable: false,
      trafficTicketForm: {
        clientOrderId: null,
        ticketId: null, // 罰單單號
        ticketStatus: '0',
        ticketDate: null, // 違規日期
        zipCode: null, // 郵遞區號
        address: null, // 通訊地址
        note: null, // 罰單備註
        arrivalDate: null, // 應到案日期
        ticketPrice: null, // 罰單金額
        deductionPrice: null, // 扣款金額
      },
      formData: null
    }
  },
  computed: {
      userInfo(){
        return {
          auth:{
              userType:this.$store.getters.userType
          }
        }
      },
  },
  created () {
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=>{
        this.$store.dispatch('login',res.data)
        this.qStoreStatus = '' // 預設"全部"
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.qAreaId = this.areas[0].code
      if(res.data.authStores.length>=1){
        this.stores.push({code:'',disName:'全部'})
        this.returnStoreId.push({code:'',disName:'全部'})
      }
      res.data.authStores.forEach(e => {
        this.stores.push(e)
        this.returnStoreId.push(e)
      });
      if(this.$route.query.qType == 'get7') {
          this.qStoreId = this.$route.query.qStoreId
          this.qType = "get7"
      }
      if(this.$route.query.qType == 'autoCancel') {
          this.qStoreId = this.$route.query.qStoreId
          this.qType = "autoCancel"
      }
        this.query()
    }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }        
    });   
    let flowCombo=this.$flow_combobox();
    this.flows.push({code:'',disName:'全部'})
    flowCombo.forEach(e=>{
      this.flows.push(e)
    });
    let clientTypeCombo=this.$clientType_combobox();
    this.clientTypes.push({code:'',disName:'全部'})
    clientTypeCombo.forEach(e=>{
      this.clientTypes.push(e)
    });

  },
  name: 'AdminClientOrder',
  methods :{
    displayStore(item) {
      const areaSelect = item.areaId == this.qAreaId || this.qAreaId == ''
      const statusSelect = item.status == this.qStoreStatus || this.qStoreStatus == ''
      return areaSelect && statusSelect
    },
    confirmEditReserveNote(confirmType){
      let url = ''
      let param = {}
      let confirmText = ''
      if(confirmType === 'reserveNote'){
        url = '../api/clientOrder/setReserveNote'
        param={
        oldReserveNote: this.form.reserveNote,
        newReserveNote: this.form_.reserveNote,
        clientOrderId: this.form.clientOrderId
        }
        confirmText='是否確定修改備註內容';
      }
    this.$confirm(confirmText,'警告',{
          confirmButtonText:'確定',
          cancelButtonText:'取消'
      }).then(() =>{
          this.$axios.post(url,param).then((res)=>{
              this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'})
              this.showDetailForm(this.form)
              this.query()
          })
      })
    },

    downloadPdf () {
      window.open('../api/clientOrder/pdf/'+this.form.clientOrderId+'.pdf');
    },
    downloadXls(){
      if (this.qClientUserId == 'kyc' && this.qSDate != '' && this.qEDate != '') {
        this.downloadKyc()
        return
      }
      if ( this.qRealStartDateStart == null ) {
        this.qRealStartDateStart = ''
      } else if ( this.qRealStartDateEnd == null ) {
        this.qRealStartDateEnd = ''
      } else if ( this.qRealEndDateStart == null ) {
        this.qRealEndDateStart = ''
      } else if ( this.qRealEndDateEnd == null ) {
        this.qRealEndDateEnd = ''
      }
      // console.log(this.qRealStartDateStart, this.qRealStartDateEnd, this.qRealEndDateStart, this.qRealEndDateEnd)

      if ( this.qRealStartDateStart == '' | this.qRealStartDateEnd == '' ) {
        if ( this.qRealEndDateStart == '' | this.qRealEndDateEnd == '' ) {
          if ( this.qPayDateStart == '' | this.qPayDateEnd == ''){
            if ( this.qCreateDateStart == '' | this.qCreateDateEnd == '') {
              if (this.qCancelDateStart == '' | this.qCancelDateEnd == ''){
                if(this.qSDate == '' | this.qEDate == ''){
                  this.$alert('請填寫租任意日期區間', '結果', { confirmButtonText: '確定' });
                  return
                }
              }
            }
          }
        }
      }
      if ( this.qSDate == '' | this.qEDate == '' ) {
        if ( this.qSDate != '' | this.qEDate != '' ) {
          this.$alert('請正確填寫租車日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( this.qRealStartDateStart == '' | this.qRealStartDateEnd == '' ) {
        if ( this.qRealStartDateStart != '' | this.qRealStartDateEnd != '' ) {
          this.$alert('請正確填寫實際租車日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( this.qRealEndDateStart == '' | this.qRealEndDateEnd == '' ) {
        if ( this.qRealEndDateStart != '' | this.qRealEndDateEnd != '' ) {
          this.$alert('請正確填寫實際還車日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      
      if ( this.qCreateDateStart == '' | this.qCreateDateEnd == '' ) {
        if ( this.qCreateDateStart != '' | this.qCreateDateEnd != '' ) {
          this.$alert('請正確訂單成立日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      
      if ( this.qCancelDateStart == '' | this.qCancelDateEnd == '' ) {
        if ( this.qCancelDateStart != '' | this.qCancelDateEnd != '' ) {
          this.$alert('請正確訂單取消日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( moment(this.qEDate) - moment(this.qSDate) > 365*86400000 ) { // 86400000 一天
        this.$alert('租車日期區間不可超過一年', '結果', { confirmButtonText: '確定' });
        return
      }
      if ( moment(this.qRealStartDateEnd) - moment(this.qRealStartDateStart) > 365*86400000 ) { // 86400000 一天
        this.$alert('實際租車日期區間不可超過一年', '結果', { confirmButtonText: '確定' });
        return
      }
      if ( moment(this.qRealEndDateEnd) - moment(this.qRealEndDateStart) > 365*86400000 ) {
        this.$alert('實際還車日期區間不可超過一年', '結果', { confirmButtonText: '確定' });
        return
      }
      if ( moment(this.qPayDateEnd) - moment(this.qPayDateStart) > 365*86400000 ) {
        this.$alert('付款日期區間不可超過一年', '結果', { confirmButtonText: '確定' });
        return
      }
      if ( moment(this.qCreateDateEnd) - moment(this.qCreateDateStart) > 365*86400000 ) {
        this.$alert('訂單成立日期區間不可超過一年', '結果', { confirmButtonText: '確定' });
        return
      }
      if ( moment(this.qCancelDateEnd) - moment(this.qCancelDateStart) > 365*86400000 ) {
        this.$alert('訂單取消日期區間不可超過一年', '結果', { confirmButtonText: '確定' });
        return
      }
      let end_date_moment;
      end_date_moment = moment(this.qSDate);
      let _qSDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qEDate);
      let _qEDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      let _qEDateStart = this.qEDateStart == null || this.qEDateStart == '' ? '' : moment(this.qEDateStart).format('YYYYMMDD')
      let _qEDateEnd = this.qEDateEnd == null || this.qEDateEnd == '' ? '' : moment(this.qEDateEnd).format('YYYYMMDD')

      end_date_moment = moment(this.qRealStartDateStart);
      let _qRealStartDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qRealStartDateEnd);
      let _qRealStartDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qRealEndDateStart);
      let _qRealEndDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qRealEndDateEnd);
      let _qRealEndDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      end_date_moment = moment(this.qPayDateStart);
      let _qPayDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qPayDateEnd);
      let _qPayDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      //
      end_date_moment = moment(this.qCreateDateStart);
      let _qCreateDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qCreateDateEnd);
      let _qCreateDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      
      end_date_moment = moment(this.qCancelDateStart);
      let _qCancelDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qCancelDateEnd);
      let _qCancelDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      window.open('../api/clientOrder/export/excel?qOrderType=' + this.qOrderType
                +'&qClientUserId=' + this.qClientUserId
                +'&qClientOrderId=' + this.qClientOrderId
                +'&qPhone=' + this.qPhone
                +'&qCarNo=' + this.qCarNo
                +'&qFlow=' + this.qFlow
                +'&qSDate='+_qSDate
                +'&qEDate='+_qEDate
                +'&qEDateStart=' + _qEDateStart
                +'&qEDateEnd=' + _qEDateEnd
                +'&qRealStartDateStart=' + _qRealStartDateStart
                +'&qRealStartDateEnd=' + _qRealStartDateEnd
                +'&qRealEndDateStart=' + _qRealEndDateStart
                +'&qRealEndDateEnd=' + _qRealEndDateEnd
                +'&qStoreId=' + this.qStoreId
                +'&qReturnStoreId=' + this.qReturnStoreId
                +'&qPayDateStart=' + _qPayDateStart
                +'&qPayDateEnd=' + _qPayDateEnd
                +'&qCreateDateStart=' + _qCreateDateStart
                +'&qCreateDateEnd=' + _qCreateDateEnd
                +'&qCancelDateStart=' + _qCancelDateStart
                +'&qCancelDateEnd=' + _qCancelDateEnd
                +'&qClientType='+this.qClientType
                );
    },
    query(){
      this.fullscreenLoading = true
      let end_date_moment;
      end_date_moment = moment(this.qSDate);
      let _qSDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qEDate);
      let _qEDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      let _qEDateStart = this.qEDateStart == null || this.qEDateStart == '' ? '' : moment(this.qEDateStart).format('YYYYMMDD')
      let _qEDateEnd = this.qEDateEnd == null || this.qEDateEnd == '' ? '' : moment(this.qEDateEnd).format('YYYYMMDD')

      end_date_moment = moment(this.qRealStartDateStart);
      let _qRealStartDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qRealStartDateEnd);
      let _qRealStartDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qRealEndDateStart);
      let _qRealEndDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qRealEndDateEnd);
      let _qRealEndDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      end_date_moment = moment(this.qPayDateStart);
      let _qPayDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qPayDateEnd);
      let _qPayDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      end_date_moment = moment(this.qCreateDateStart);
      let _qCreateDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qCreateDateEnd);
      let _qCreateDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      end_date_moment = moment(this.qCancelDateStart);
      let _qCancelDateStart = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qCancelDateEnd);
      let _qCancelDateEnd = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      let qForm= {
        page: this.pageCurrent,
        qAreaId: this.qAreaId,
        qStoreStatus: this.qStoreStatus,
        qClientUserId: this.qClientUserId,   
        qClientOrderId: this.qClientOrderId,
        qOrderType: this.qOrderType,
        qPhone: this.qPhone,
        qCarNo: this.qCarNo,
        qFlow: this.qFlow,
        qClientType: this.qClientType,
        qEDateStart: _qEDateStart,
        qEDateEnd: _qEDateEnd,
        qRealStartDateStart: _qRealStartDateStart,
        qRealStartDateEnd: _qRealStartDateEnd,
        qRentDateType: this.qRentDateType,
        qRealEndDateStart: _qRealEndDateStart,
        qRealEndDateEnd: _qRealEndDateEnd,
        qStoreId: this.qStoreId,
        qReturnStoreId: this.qReturnStoreId,
        qPayDateStart: _qPayDateStart,
        qPayDateEnd: _qPayDateEnd,
        qCreateDateStart: _qCreateDateStart,
        qCreateDateEnd: _qCreateDateEnd,
        qCancelDateStart: _qCancelDateStart,
        qCancelDateEnd: _qCancelDateEnd,
        qSDate: _qSDate,
        qEDate: _qEDate,
        qType:this.qType

      }
      this.$axios.get('../api/clientOrder/getClientOrders', {params:qForm}).then((res)=>{
        this.tableData.splice(0, this.tableData.length)
        res.data.data.forEach(store=>{
          store.sDate=this.$date_(store.sDate);
          if (store.flow == '100') {
            store.flowType=''
          } else if (store.flow == '500' || store.flow == '600' ||  store.flow == '1000' || store.flow == '1100') {
            store.flowType='info'
          } else if (store.flow == '50') {
            store.flowType='success'
          } else if (store.flow == '10') {
            store.flowType='warning'
          } else if (store.flow == '400' || store.flow == '0' ) {
            store.flowType='danger'
          }
          store.flow=this.$flow_(store.flow);
          this.tableData.push(store)
        })
        this.pageTotal=res.data.pageTotal
        this.fullscreenLoading = false
      }).catch(()=>{
          this.fullscreenLoading = false
          this.$alert('發生不知名錯誤', '結果', { confirmButtonText: '確定' });
      });  
    },
    reset(){
      this.qClientUserId=''
      this.qClientOrderId=''
      this.qSDate=''
      this.qEDate=''
      this.qEDateStart=''
      this.qEDateEnd=''
      this.qRealStartDateStart=''
      this.qRealStartDateEnd=''
      this.qRealEndDateStart=''
      this.qRealEndDateEnd=''
      this.qPayDateEnd=''
      this.qPayDateStart=''
      this.qStoreId=this.stores[0].code
      this.qReturnStoreId=this.returnStoreId[0].code
      this.qFlow=this.flows[0].code
      this.qPhone=''
      this.qCarNo=''
      this.qCancelDateEnd=''
      this.qCancelDateStart=''
      this.qCreateDateStart=''
      this.qCreateDateEnd=''
      this.qStoreStatus=''
    },
    showDetailForm(clientOrder){
      this.form.tenancyDeposit = ''
      this.historyLabel = ''
      this.historyLabelColor = ''
      this.ratio = '尚未驗證過'
      this.fullscreenLoading = true
      this.kycHistory = Object.assign([],[])
      this.$axios.get('../api/clientOrder/getClientOrderForm?clientOrderId='+clientOrder.clientOrderId).then((res)=>{
        this.transName = '0'
        this.dialogFormVisible = true
        this.form = Object.assign({}, this.form, res.data[0])
        if(this.form.plusPrice=='0'||this.form.plusPrice==''){
          this.form.plusPrice = '0'
        }
        
        if(this.form.beforePrice == null) {
          this.form.beforePrice = 0
        }
        if (this.form.vatNo != null && this.form.vatNo != '' && this.form.vatNo != undefined) {
          this.form.invoiceType = '統編'
        } else if (this.form.carrierNum != null && this.form.carrierNum != '' && this.form.carrierNum != undefined) {
          this.form.invoiceType = '個人載具'
        } else {
          this.form.invoiceType = 'Email'
        }
        if (this.form.invoiceId == '' || this.form.invoiceId == null || this.form.invoiceId == undefined) {
          this.form.invoiceType = ''
        }

        switch(this.form.motoLicense){
          case "M":
            this.form.motoLicenseType = '普通重型機車( 150 CC以下)'
            break
          case "S":
            this.form.motoLicenseType = '輕型機車( 50 CC以下)'
            break
          case "N":
            this.form.motoLicenseType = '沒有駕照（微型電動車）'
            break
          default:
            this.form.motoLicenseType = '資料錯誤'
            break
        }

        this.form.prePrice = this.form.price - this.form.deposit - this.form.beforePrice
        this.form.tenancyDeposit = this.form.tenancyDeposit === '' ? 0 : this.form.tenancyDeposit
        this.form.accessoriesRaincoat = this.form.accessoriesRaincoat === null? 0: this.form.accessoriesRaincoat
        this.form.accessoriesHelmet = this.form.accessoriesHelmet === null? 0: this.form.accessoriesHelmet
        this.form.accessoriesHelmetFull = this.form.accessoriesHelmetFull === null? 0: this.form.accessoriesHelmetFull
        this.form.clientBirthday = moment(this.form.clientBirthday).format('YYYY/MM/DD')
        this.form.eDate = this.$date_(this.form.eDate)
        this.form.sDate = this.$date_(this.form.sDate)
        this.form.realStartDate = this.$date_(this.form.realStartDate)
        this.form.realEndDate = this.$date_(this.form.realEndDate)
        this.form.isShowTrafficOp = !this.$isEmpty(this.form.realStartDate) && !this.$isEmpty(this.form.realEndDate)
        this.form.nowDistance = (parseInt(this.form.startDistance) + 1).toString()
        this.form.plusInsuranceDisName = this.form.plusInsurance == '1' ? '是' : '否'
        this.form.rentDateTypeDisName= this.form.rentDateType == '2'? '長租' : '短租'
        this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)
        this.form.depositPayMethod = this.$payTypeDisName(this.form.depositPayMethod)
        if (this.form.pic1 === null) { this.form.pic1 = 'clientUser/null'}
        if (this.form.pic2 === null) { this.form.pic2 = 'clientUser/null'}
        if (this.form.pic3 === null) { this.form.pic3 = 'clientUser/null'}
        if (this.form.pic4 === null) { this.form.pic4 = 'clientUser/null'}

        this.pic1 = '../api/adminClientUser/getUserPic/'+this.form.pic1
        this.pic2 = '../api/adminClientUser/getUserPic/'+this.form.pic2
        this.pic3 = '../api/adminClientUser/getUserPic/'+this.form.pic3
        this.pic4 = '../api/adminClientUser/getUserPic/'+this.form.pic4
        this.historyFlow.splice(0, this.historyFlow.length)
        res.data[0].historyFlow.forEach(e=>{
          e.updateDate=this.$date_(e.updateDate)
          this.historyFlow.push(e)
        })
        this.historyBooking.splice(0, this.historyBooking.length)
        res.data[0].historyBooking.forEach(e=>{
          e.createDate=this.$date_(e.createDate)
          this.historyBooking.push(e)
        })

        // 簽名檔圖片
        if (res.data[0].clientOrderSignature.length == null | res.data[0].clientOrderSignature.length == 0) {
          this.clientOrderSignature = null
        } else if (res.data[0].clientOrderSignature != null) {
          this.clientOrderSignature = this.form.clientOrderSignature[0].data
        }
        
        this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
        res.data[0].clientOrderTrans.forEach(e=>{
          if (e.moneyType == 'CASH') {
            e.moneyType = '現金'
          } else if (e.moneyType == 'CREDIT') {
            e.moneyType = '信用卡'
          } else if (e.moneyType == 'LINEPAY') {
            e.moneyType = 'LINE Pay'
          } else if (e.moneyType == 'ATM') {
            e.moneyType = 'ATM 轉帳'
          } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
          } else { e.moneyType = '未知付款方式' }
          e.updateDate = this.$date_(e.updateDate)
          if (e.status !== 'SUCCESS') {
            e.updateDate = e.updateDate + '(付款失敗)'
          }
          this.clientOrderTrans.push(e)
        })
        // KYC 
          let list = res.data[0].kycHistory.historys
          this.ratio = res.data[0].kycHistory.ratio
          if (this.ratio!== undefined && this.ratio !== '') {
            this.ratio = this.ratio + '分'
            let color = ['#25CD00','#B0CF00','#805500','#CF7D00','#CF0000']
            let ratioInt = parseInt(this.ratio)
            this.historyLabelColor = ''
            if (ratioInt === 100) {
              this.historyLabelColor = 'color:' + color[0] + ''
              this.historyLabel = '優良駕駛'
            } else if (ratioInt >= 80 && ratioInt < 100) {
              this.historyLabelColor = 'color:' + color[1] + ''
              this.historyLabel = '普通駕駛'
            } else if (ratioInt >= 60 && ratioInt < 80) {
              this.historyLabelColor = 'color:' + color[2] + ''
              this.historyLabel = '少量違規駕駛，需稍加注意'
            } else if (ratioInt >= 40 && ratioInt < 60) {
              this.historyLabelColor = 'color:' + color[3] + ''
              this.historyLabel = '中量違規駕駛，不建議租借'
            } else if (ratioInt < 40) {
              this.historyLabelColor = 'color:' + color[4] + ''
              this.historyLabel = '大量違規駕駛，不建議租借'
            }
          } else {
            this.ratio = '尚未驗證過'
          }
          list.forEach(e => {
            e.updateDate = this.$date_(e.updateDate)
            e.statusType = this.statusTypeMap[e.status]
            e.status = this.statusMap[e.status]
            e.show = false

            if (e.vehicleJudgement != null) {
              let vs = JSON.parse(e.vehicleJudgement).data
              e.vs = []
              for (var v in vs) {
                if(v !== 'mvdis_tickets'){
                  e.vs.push({disName: v, value :vs[v]})
                } else {
                  if(vs[v]['可線上支付']!=null){
                    let vs1=vs[v]['可線上支付']
                    for(var v1 in vs1){
                      e.vs.push({disName: '可線上支付', value :vs1[v1]})
                    }
                  }
                  if(vs[v]['不可線上支付']!=null){
                    let vs2=vs[v]['不可線上支付']
                    for(var v2 in vs2){
                      e.vs.push({disName: '不可線上支付', value :vs2[v2]})
                    }
                  }
                }
              }
            }
            if (e.commonJudgement != null) {
              let cs = JSON.parse(e.commonJudgement).data
              e.cs = []
              for (var c in cs) {
                  e.cs.push({disName: c, value :cs[c]})
              }
            }
            this.kycHistory.push(e)
            if (e.status === '0') {
              this.kycStatus = e.status
            }
          })
          this.fullscreenLoading = false
      }).catch(()=>{
          this.$alert('發生不知名錯誤', '結果', { confirmButtonText: '確定' })
      });
    },
    deleteRow(index) {
      console.log(index)
    },
    banUser() {
      this.banAlert = true
    },
    confirmBanClientUser(){
      let pa = { banNote: this.form.banNote,
                clientUserId: this.form.clientUserId }
      this.$axios.post('../api/adminClientUser/banClientUser',pa).then(res => {
        if(res.data.rs == 'success') {
          this.$alert('加入黑名單成功','結果',{confirmButtonText:'確定'})
        } else {
          this.$alert('加入黑名單失敗','結果',{confirmButtonText:'確定'})
        }
        this.banAlert = false 
        this.showDetailForm(this.form)
      })
    },
    showTrafficTicketForm() {
      this.trafficTicketForm = {
        clientOrderId: this.form.clientOrderId,
        ticketId: null,
        ticketDate: null,
        ticketStatus: '0',
        zipCode: null,
        address: null,
        note: null,
        arrivalDate: null,
        ticketPrice: null,
        deductionPrice: null
      }
      this.trafficTicketFormVisable = true
    },
    checkTrafficTicketId() {
      this.fullscreenLoading = true
      this.$axios.get('../api/clientOrder/checkTrafficTicketId', {params: this.trafficTicketForm}).then((res)=>{
        this.fullscreenLoading = false
        if (res.data.rs !== 'success') {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        }
      }).catch(()=>{
          this.fullscreenLoading = false
          this.$alert('發生不知名錯誤', '結果', { confirmButtonText: '確定' });
      });  
    },
    checkTicketDate() {
      let ticketDate = moment(this.trafficTicketForm.ticketDate)
      let realStartDate = moment(this.form.realStartDate,'YYYY-MM-DD')
      let realEndDate = moment(this.form.realEndDate,'YYYY-MM-DD')
      if (realStartDate.isAfter(ticketDate) || realEndDate.isBefore(ticketDate)) {
        this.$alert('違規日期不在承租區間','警告',{confirmButtonText:'確定'})
      }
    },
    confirmCreateTrafficTicket() {
      if(this.$isEmpty(this.trafficTicketForm.ticketId)) {
        this.$alert('請輸入罰單單號','警告',{confirmButtonText:'確定'})
        return
      }
      if(this.$isEmpty(this.trafficTicketForm.ticketPrice)) {
        this.$alert('請輸入罰單金額','警告',{confirmButtonText:'確定'})
        return
      }
      if(this.$isEmpty(this.trafficTicketForm.ticketDate)) {
        this.$alert('請輸入違規日期','警告',{confirmButtonText:'確定'})
        return
      }
      if(this.$isEmpty(this.trafficTicketForm.zipCode)) {
        this.$alert('請輸入郵遞區號','警告',{confirmButtonText:'確定'})
        return
      }
      if(this.$isEmpty(this.trafficTicketForm.address)) {
        this.$alert('請輸入通訊地址','警告',{confirmButtonText:'確定'})
        return
      }
      if(this.$isEmpty(this.trafficTicketForm.arrivalDate)) {
        this.$alert('請輸入應到案日期','警告',{confirmButtonText:'確定'})
        return
      }
      let postData = {
        clientOrderId: this.trafficTicketForm.clientOrderId,
        ticketId: this.trafficTicketForm.ticketId, // 罰單單號
        ticketStatus: this.trafficTicketForm.ticketStatus,
        ticketDate: moment(this.trafficTicketForm.ticketDate).format('YYYYMMDD'), // 違規日期
        zipCode: this.trafficTicketForm.zipCode, // 郵遞區號
        address: this.trafficTicketForm.address, // 通訊地址
        note: this.trafficTicketForm.note, // 罰單備註
        ticketPrice: this.trafficTicketForm.ticketPrice, // 罰單金額
        deductionPrice: (this.trafficTicketForm.ticketPrice * 1.1).toFixed(0), // 扣款金額
        arrivalDate: moment(this.trafficTicketForm.arrivalDate).format('YYYYMMDD'), // 應到案日期
      }
      this.formData.append('data', JSON.stringify(postData))
      let confirmText='是否確定新增罰單'
      let url = '../api/clientOrder/createTrafficTicket'
      this.$confirm(confirmText,'警告',{
          confirmButtonText:'確定',
          cancelButtonText:'取消'
      }).then(() =>{
          this.$axios.post(url, this.formData).then((res)=>{
            if(res.data.rs == 'success') {
              location.reload()
              this.$alert('新增成功','結果',{confirmButtonText:'確定'})
            } else {
              this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'})
            }
          }).catch(() => {
            this.$alert('未知錯誤','結果',{confirmButtonText:'確定'})
          })
      })
    },
    unBanUser() {
      this.$confirm('解除黑名單後，該顧客帳號將可於所有門市租借車輛','',{confirmButtonText:'',cancelButtonText:''}).then(()=>{
        let pa = { clientUserId: this.form.clientUserId }
        this.$axios.post('../api/adminClientUser/unbanClientUser',pa).then(res => {
        if(res.data.rs == 'success') {
          this.$alert('解除黑名單成功','結果',{confirmButtonText:'確定'})
        } else {
          this.$alert('解除黑名單失敗','結果',{confirmButtonText:'確定'})
        }
        this.showDetailForm(this.form)
        })
      })
    },
    downloadKyc(){
      let end_date_moment;
      end_date_moment = moment(this.qSDate);
      let _qSDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.qEDate);
      let _qEDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      window.open('../api/kycDialog/downloadKyc?sDate='+_qSDate
      +'&eDate=' +_qEDate)
    },
    async fileChange(e) {
      this.formData=new FormData();
      this.formData.append('file', e.target.files[0])
      //放進上傳的檔案
    },  
  }
}
</script>
<style>

  .editReserveNote{
    margin-left: 10px;
    border-style:solid; 
    border-width:1px;
  }
  .editReserveNote .editReserveNoteSureButton {
    margin-left: 10px;
  }
</style>
