<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="罰單單號" v-model="qTicketId">
            <template slot="prepend">罰單單號</template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-date-picker placeholder="應到案日期(起)" v-model="qArrivalSDate">
            <template slot="prepend">應到案日期(起)</template>
          </el-date-picker>~
          <el-date-picker placeholder="罰單登記日期(迄)" v-model="qArrivalEDate">
            <template slot="prepend">罰單登記日期(迄)</template>
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          狀態<el-select v-model="qStatus" multiple>
            <el-option
              v-for="item in statusList"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="9">
            排序<el-radio-group v-model="qOrderType" size="medium" @change="query">
              <el-radio-button :label="orderType_1"></el-radio-button>
              <el-radio-button :label="orderType_2"></el-radio-button>
            </el-radio-group>      
          </el-col>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-col :span="5"
          ><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
          <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="clientOrderId" width="160px" label="訂單編號"></el-table-column>
        <el-table-column prop="carNo" width="100px" label="車號"></el-table-column>
        <el-table-column prop="ticketId" width="100px" label="單號"> </el-table-column>
        <el-table-column prop="ticketStatusDis" label="狀態"></el-table-column>
        <el-table-column prop="ticketDateDis" width="100px" label="違規日期"> </el-table-column>
        <el-table-column prop="sDateDis" width="110px" label="租約起始日期"> </el-table-column>
        <el-table-column prop="sTimeDis" width="110px" label="租約起始時間"></el-table-column>
        <el-table-column prop="eDateDis" width="110px" label="租約結束日期"></el-table-column>
        <el-table-column prop="eTimeDis" width="110px" label="租約結束時間"> </el-table-column>
        <el-table-column prop="clientUserDisName" width="100px" label="租用人姓名"> </el-table-column>
        <el-table-column prop="clientUserId" width="100px" label="用戶帳號"> </el-table-column>
        <el-table-column prop="arrivalDateDis" width="100px" label="應到案日期"> </el-table-column>

        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              @click="showDetailForm(scope.row)"
              type="primary"
              size="small"
              >編輯</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal"
        :current-page.sync="pageCurrent"
        @current-change="query"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="訂單號" :label-width="formLabelWidth">
          {{form.clientOrderId}}
        </el-form-item>
        <el-form-item label="車號" :label-width="formLabelWidth">
          {{form.carNo}}
        </el-form-item>
        <el-form-item label="單號" :label-width="formLabelWidth">
          {{form.ticketId}}
        </el-form-item>
        <el-form-item label="金額" :label-width="formLabelWidth">
          {{form.ticketPrice}}
        </el-form-item>
        <el-form-item label="扣款" :label-width="formLabelWidth">
          {{form.deductionPrice}}
        </el-form-item>
        <el-form-item label="狀態" :label-width="formLabelWidth">
          <el-select v-model="form.ticketStatus">
            <el-option
              v-for="item in statusList"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
              disabled
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="違規日期" :label-width="formLabelWidth">
          {{form.ticketDateDis}}
        </el-form-item>
        <el-form-item label="租約起始日期" :label-width="formLabelWidth">
          {{form.sDateDis}}
        </el-form-item>
        <el-form-item label="租約起始時間" :label-width="formLabelWidth">
          {{form.sTimeDis}}
        </el-form-item>
        <el-form-item label="租約結束日期" :label-width="formLabelWidth">
          {{form.eDateDis}}
        </el-form-item>
        <el-form-item label="租約結束時間" :label-width="formLabelWidth">
          {{form.eTimeDis}}
        </el-form-item>
        <el-form-item label="租用人姓名" :label-width="formLabelWidth">
          {{form.clientUserDisName}}
        </el-form-item>
        <el-form-item label="用戶帳號" :label-width="formLabelWidth">
          {{form.clientUserId}}
        </el-form-item>
        <el-form-item label="應到案日期" :label-width="formLabelWidth">
          {{form.arrivalDateDis}}
        </el-form-item>
        
        <el-form-item label="備註" :label-width="formLabelWidth">
          <el-input type="textArea" v-model="form.note"></el-input>
        </el-form-item>
        <el-form-item label="付款紀錄:" :label-width="formLabelWidth">
          <div v-for="(item, key) in ticketTrans" :key="key">
            {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
          </div>
        </el-form-item>
        <el-form-item label="復健:" :label-width="formLabelWidth">
          <a target="_blank" :href="pic"><img :src="pic" style="width:250px;height:180px;"/></a>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-show="form.ticketStatus == 0" type="primary" @click="confirmEditFlow('100')">已轉責待通知</el-button>
        <el-button v-show="form.ticketStatus == 0" type="warning" @click="confirmEditFlow('200')">待通知待扣款</el-button>
        <el-button v-show="form.ticketStatus == 100" type="primary" @click="confirmEditFlow('1000')">已通知</el-button>
        <el-button v-show="form.ticketStatus == 200" type="danger" @click="confirmPay()">扣款</el-button>
        <!-- <el-button type="primary" @click="confirmEditStore">確 定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      labelPosition: "right",
      statusList: [
        { code: '0', disName: '待處理'},
        { code: '100', disName: '已轉責待通知'},
        { code: '200', disName: '待通知待扣款'},
        { code: '400', disName: '扣款不成功待繳費'},
        { code: '500', disName: '完成(客人付款)'},
        { code: '900', disName: '扣款成功'},
        { code: '1000', disName: '已通知'},
      ],
      qTicketId: "",
      qArrivalSDate: null,
      qArrivaleDate: null,
      qStatus: [],
      qOrderType: '應到案日期↑',
      orderType_1:'應到案日期↑',orderType_2:'應到案日期↓',
      tableData: [],
      ticketTrans: [],
      dialogFormVisible: false,
      form: {
        rowId: null,
        ticketStatus: null,
        note: null
      },
      formLabelWidth: "120px",
      pic: ''
    };
  },
  created() {
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=>{
        this.$store.dispatch('login',res.data)
        this.query()
    }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }        
    })
  },
  name: "AdminTrafficTicket",
  components: {},
  computed: {},
  methods: {
    query() {
      let qSDate = this.$isEmpty(this.qArrivalSDate) ? '' : moment(this.qArrivalSDate).format('YYYYMMDD')
      let qEDate = this.$isEmpty(this.qArrivalEDate) ? '' : moment(this.qArrivalEDate).format('YYYYMMDD')
      // orderType_1:'應到案日期↑',orderType_2:'應到案日期↓',
      let orderType = this.qOrderType == '應到案日期↓' ? '' : 'desc'
      let status = JSON.stringify(this.qStatus)
      let status_ = status.substring(1, status.length -1)
      this.$axios
        .get("../api/trafficTicket/getTrafficTickets?", {
          params: {
            qTicketId: this.qTicketId,
            qArrivalSDate: qSDate,
            qArrivalEDate: qEDate,
            qStatus: status_,
            qOrderType: orderType,
            page: this.pageCurrent
          }
        })
        .then(res => {
          this.tableData.splice(0, this.tableData.length);
          res.data.data.forEach(e => {
            e.ticketDateDis = moment(e.ticketDate, 'YYYYMMDD').format('YYYY-MM-DD')
            e.arrivalDateDis = moment(e.arrivalDate, 'YYYYMMDD').format('YYYY-MM-DD')
            e.sDateDis = moment(e.realStartDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')
            e.sTimeDis = moment(e.realStartDate, 'YYYYMMDDHHmmss').format('HH:mm:ss')
            e.eDateDis = moment(e.realEndDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')
            e.eTimeDis = moment(e.realEndDate, 'YYYYMMDDHHmmss').format('HH:mm:ss')
            this.statusList.forEach(item => {
              if (item.code == e.ticketStatus) {
                e.ticketStatusDis = item.disName
              }
            })
            this.tableData.push(e)
          })
          this.pageTotal = res.data.pageTotal;
        })
        .catch(res => {
          this.$alert('不知名錯誤:' + res, '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditStore() {
      let url = "../api/trafficTicket/updateTrafficTicket";
      this.$confirm('是否確定編輯','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then(() => {
            this.query();
            this.dialogFormVisible = false;
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    confirmEditFlow(status) {
      let url = "../api/trafficTicket/updateTrafficTicketStataus";
      this.$confirm('是否確定更改狀態','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        let postData = {
          ticketStatus: status,
          rowId: this.form.rowId,
          note: this.form.note
        }
        this.$axios
          .post(url, postData)
          .then(() => {
            this.query();
            this.dialogFormVisible = false;
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    confirmPay() {
      let url = "../api/trafficTicket/payTrafficTicketByCard";
      this.$confirm('是否確定扣款','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then((res) => {
            if (res.data.rs == 'success') {
              this.query();
              this.dialogFormVisible = false;
            }
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    showDetailForm(ticket) {
      this.$axios.get('../api/trafficTicket/getTicketDetail?rowId='+ticket.rowId).then((res)=>{
        this.form = Object.assign({}, this.form, res.data);
        this.ticketTrans.splice(0, this.ticketTrans.length)
        this.pic = '../api/trafficTicket/getPic/' + this.form.pic
        res.data.ticketTrans.forEach(e=>{
          if (e.moneyType == 'CASH') {
            e.moneyType = '現金'
          } else if (e.moneyType == 'CREDIT') {
            e.moneyType = '信用卡'
          } else if (e.moneyType == 'LINEPAY') {
            e.moneyType = 'LINE Pay'
          } else if (e.moneyType == 'ATM') {
            e.moneyType = 'ATM 轉帳'
          } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
          } else { e.moneyType = '未知付款方式' }
          e.updateDate = this.$date_(e.updateDate)
          if (e.status !== 'SUCCESS') {
            e.updateDate = e.updateDate + '(付款失敗)'
          }
          this.ticketTrans.push(e)
        })
        this.dialogFormVisible = true

      })
    },
    downloadExcel () {
      if ( this.qStartInvoiceDate == null | this.qEndInvoiceDate == null ) {
        if ( this.qStartInvoiceDate != null | this.qEndInvoiceDate != null ) {
          this.$alert('請正確填寫發票開立日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( moment(this.qEndInvoiceDate) - moment(this.qStartInvoiceDate) > 90*86400000 ) { // 86400000 一天
        this.$alert('發票開立日期區間不可超過90天', '結果', { confirmButtonText: '確定' });
        return
      }

      let qDate = this.$isEmpty(this.qCreateDate) ? '' : moment(this.qCreateDate).format('YYYYMMDD')
      let status = JSON.stringify(this.qStatus)
      let status_ = status.substring(1, status.length -1)
      
      window.open('../api/trafficTicket/export/excel?qTicketId='+this.qTicketId
      +'&qCreateDate='+qDate
      +'&qStatus='+status_
      
      )
    },
  }
};
</script>
