<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-row :gutter="22">
        <el-col :span="8">
          <el-input placeholder="訂單編號" v-model="qClientOrderId">
            <template slot="prepend">訂單編號</template>
          </el-input>
        </el-col>
        <!-- <el-col :span="8">
          <el-input placeholder="訂單編號" v-model="qClientOrderId">
            <template slot="prepend">訂單編號</template>
          </el-input>
        </el-col> -->
        <el-col :span="8">
          <el-date-picker placeholder="建立日期(起)" v-model="qSDate">
            <template slot="prepend">建立日期(起)</template>
          </el-date-picker>~
          <el-date-picker placeholder="建立日期(迄)" v-model="qEDate">
            <template slot="prepend">建立日期(迄)</template>
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <span style="margin-right: 5px;">狀態</span>
          <el-select v-model="qStatus">
            <el-option
              v-for="item in statusList"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-col :span="24"
          ><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
          <el-button type="primary" v-show="userInfo.auth.userType === '0'" @click="showDetailForm(null)">新增</el-button>
          <!-- <el-button type="primary" @click="downloadExcel()">下載報表</el-button> -->
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="clientOrderId" width="160px" label="訂單編號"></el-table-column>
        <el-table-column prop="clientUserDisName" width="100px" label="租用人姓名"> </el-table-column>
        <el-table-column prop="clientUserId" width="100px" label="用戶帳號"> </el-table-column>
        <el-table-column prop="phone" width="100px" label="電話"> </el-table-column>
        <el-table-column prop="flowDis" label="狀態"></el-table-column>
        <el-table-column prop="storeDisName" width="100px" label="租借店家"> </el-table-column>
        <el-table-column prop="sDateDis" width="110px" label="租約起始日期"> </el-table-column>
        <el-table-column prop="sTimeDis" width="110px" label="租約起始時間"></el-table-column>
        <el-table-column prop="eDateDis" width="110px" label="租約結束日期"></el-table-column>
        <el-table-column prop="eTimeDis" width="110px" label="租約結束時間"> </el-table-column>
        <el-table-column prop="reason" width="100px" label="原因"></el-table-column>
        <el-table-column prop="note" width="100px" label="備註"> </el-table-column>
        <el-table-column prop="hadBoundleCard" width="100px" label="綁定信用卡狀態"> </el-table-column>

        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              @click="showDetailForm(scope.row)"
              type="primary"
              size="small"
              >編輯</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal"
        :current-page.sync="pageCurrent"
        @current-change="query"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="訂單號" :label-width="formLabelWidth">
          <el-input v-model="form.clientOrderId" :disabled="isEdit" @change="getClientOrderDetail"></el-input>
        </el-form-item>
        <el-form-item label="車號" :label-width="formLabelWidth">
          {{form.carNo}}
        </el-form-item>
        <el-form-item label="門市" :label-width="formLabelWidth">
          {{form.storeDisName}}
        </el-form-item>
        <el-form-item label="金額" :label-width="formLabelWidth">
          <el-input v-model="form.price" :disabled="!canEdit"></el-input>
        </el-form-item>
        <el-form-item label="狀態" :label-width="formLabelWidth">
          <el-select v-model="form.flow">
            <el-option
              v-for="item in statusList"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
              disabled
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租用人姓名" :label-width="formLabelWidth">
          {{form.clientUserDisName}}
        </el-form-item>
        <el-form-item label="用戶帳號" :label-width="formLabelWidth">
          {{form.clientUserId}}
        </el-form-item>
        <el-form-item label="是否綁定信用卡" :label-width="formLabelWidth">
          {{form.hadBoundleCard}}
        </el-form-item>
        <el-form-item label="載具號碼" :label-width="formLabelWidth">
          <el-input v-model="form.carrierNum" :disabled="!canEdit"></el-input>
        </el-form-item>
        <el-form-item label="統編" :label-width="formLabelWidth">
          <el-input v-model="form.vatNo" :disabled="!canEdit"></el-input>
        </el-form-item>
        <el-form-item label="抬頭" :label-width="formLabelWidth">
          <el-input v-model="form.vatTitle" :disabled="!canEdit"></el-input>
        </el-form-item>
        <el-form-item label="原因" :label-width="formLabelWidth">
          <el-input type="textArea" v-model="form.reason"></el-input>
        </el-form-item>
        <el-form-item label="備註" :label-width="formLabelWidth">
          <el-input type="textArea" v-model="form.note"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="danger" v-show="form.flow == '100' && isEdit" @click="confirmEditFlow('0')">退回代處理</el-button>
        <el-button type="primary" @click="confirmEditStore">儲存</el-button>
        <el-button type="warning" v-show="form.flow == '0' && isEdit" @click="confirmEditFlow('100')">資料無誤等待扣款</el-button>
        <el-button type="danger" v-show="form.flow == '100'" @click="confirmPayByCard">信用卡扣款</el-button>
        <el-button type="info" v-show="form.flow == '100'" @click="confirmPayByCash">現金收款</el-button>
        <el-button type="warning" v-show="form.flow == '100'" @click="confirmPayByATM">轉帳收款</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      labelPosition: "right",
      statusList: [
        { code: '0', disName: '待處理'},
        { code: '100', disName: '代扣款'},
        { code: '500', disName: '扣款成功'},
      ],
      qClientOrderId: "",
      qSDate: null,
      qEDate: null,
      qStatus: '',
      qForm_:{},
      tableData: [],
      dialogFormVisible: false,
      form: {
        clientOrderId: '',
        flow: '0',
        price: 0,
        note: '',
        reason: '',
        moneyTypeExtand: '',
        carrierNum: '',
        vatNo: '',
        vatTitle: '',
        clientUserDisName: '',
        clientUserId: '',
        hadBoundleCard: '',
        carNo: '',
        storeDisName: '',
      },
      formLabelWidth: "120px",
      canEdit: true,
      isEdit: true,
    };
  },
  created() {
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=>{
        this.$store.dispatch('login',res.data)
        this.query()
    }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }        
    })
  },
  name: "AdminTrafficTicket",
  components: {},
  computed: {
    userInfo(){
      return {
        auth:{
            userType:this.$store.getters.userType
        }
      }
    },
  },
  methods: {
    query() {
      let qSDate = this.$isEmpty(this.qSDate) ? '' : moment(this.qSDate).format('YYYYMMDDHHmmdd')
      let qEDate = this.$isEmpty(this.qEDate) ? '' : moment(this.qEDate).format('YYYYMMDDHHmmdd')
      let status = JSON.stringify(this.qStatus)
      let status_ = status.substring(1, status.length -1)
      this.$axios
        .get("../api/prodIncident/getProdIncidents", {
          params: {
            qClientOrderId: this.qClientOrderId,
            qSDate: qSDate,
            qEDate: qEDate,
            qStatus: status_,
            page: this.pageCurrent
          }
        })
        .then(res => {
          this.tableData.splice(0, this.tableData.length);
          res.data.data.forEach(e => {
            e.sDateDis = moment(e.realStartDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')
            e.sTimeDis = moment(e.realStartDate, 'YYYYMMDDHHmmss').format('HH:mm:ss')
            e.eDateDis = moment(e.realEndDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')
            e.eTimeDis = moment(e.realEndDate, 'YYYYMMDDHHmmss').format('HH:mm:ss')
            e.hadBoundleCard = e.boundleCreditCard == 'T' ? '綁定' : '未綁定'
            this.statusList.forEach(item => {
              if (item.code == e.flow) {
                e.flowDis = item.disName
              }
            })
            this.tableData.push(e)
          })
          this.pageTotal = res.data.pageTotal;
        })
        .catch(res => {
          this.$alert('不知名錯誤:' + res, '結果', { confirmButtonText: '確定' });
        });
    },
    getClientOrderDetail() {
      this.fullscreenLoading = true
      this.$axios
        .get("../api/prodIncident/getClientOrderDetail?clientOrderId=" + this.form.clientOrderId, {})
        .then(rs => {
          if ((rs.data.rs == 'success')) {
            this.form.carNo = rs.data.data.carNo;
            this.form.clientUserDisName = rs.data.data.clientUserDisName
            this.form.clientUserId =rs.data.data.clientUserId
            this.form.hadBoundleCard = rs.data.data.boundleCreditCard == 'T' ? '綁定' : '未綁定'
            this.form.storeDisName = rs.data.data.storeDisName
            this.canEdit = true
          } else {
            this.$alert(rs.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
          this.fullscreenLoading = false
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert("取得訂單資料異常", "結果", { confirmButtonText: "確定" });
        });
    },
    confirmEditStore() {
      let url = "../api/prodIncident/updateProdIncident";
      if (!this.isEdit) {
        url = '../api/prodIncident/createProdIncident'
      }
      this.$confirm('是否確定儲存','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then(() => {
            this.query();
            this.dialogFormVisible = false;
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    confirmEditFlow(flow) {
      this.form.flow = flow
      let url = "../api/prodIncident/updateProdIncidentFlow";
      this.$confirm('是否確定資料無誤','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then(() => {
            this.query();
            this.dialogFormVisible = false;
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    showDetailForm(row) {
      if (row) {
        this.form = Object.assign({}, this.form, row);
        this.isEdit = true
      } else {
        this.form = {
          clientOrderId: '',
          flow: '0',
          price: 0,
          note: '',
          reason: '',
          moneyTypeExtand: '',
          carrierNum: '',
          vatNo: '',
          vatTitle: '',
          clientUserDisName: '',
          clientUserId: '',
          hadBoundleCard: '',
          carNo: '',
          storeDisName: '',
        }
        this.isEdit = false
      }
      this.dialogFormVisible = true
    },
    confirmPayByCard() {
      let url = "../api/prodIncident/payProdIncidentByCard";
      this.$confirm('是否確定扣款','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then((res) => {
            if (res.data.rs == 'success') {
              this.query();
              this.dialogFormVisible = false;
            }
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    confirmPayByCash() {
      let url = "../api/prodIncident/payProdIncidentByCash";
      this.$confirm('是否確認收款','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then((res) => {
            if (res.data.rs == 'success') {
              this.query();
              this.dialogFormVisible = false;
            }
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    confirmPayByATM() {
      let url = "../api/prodIncident/payProdIncidentByATM";
      this.$confirm('是否確認收款','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, this.form)
          .then((res) => {
            if (res.data.rs == 'success') {
              this.query();
              this.dialogFormVisible = false;
            }
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
  }
};
</script>
