<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName">
      <el-row>
        <!-- 第一部分 -->
        <el-col :span="5">
          <el-row>
            <el-card shadow="always"
              style="font-size:20px;margin:0 auto;margin-top:5px;text-align:center;background-color: yellowgreen;">車輛出租<br/>發票金額</el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              MOFUN大本營<br />
              <span style="color:green;margin-left:10px">
                {{ board.rentInvoiceHead | filter_commaFormate }}
              </span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              西門店<br />
              <span style="color:green;margin-left:10px" >
                {{ board.rentInvoice1 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              基隆店<br />
              <span style="color:green;margin-left:10px">
                {{ board.rentInvoice2 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台中門店+高鐵店<br />
              <span style="color:green;margin-left:10px">
                {{ board.rentInvoice3 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台南門店+高鐵店<br />
              <span style="color:green;margin-left:10px">
                {{  board.rentInvoice4 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              高雄門店+高鐵店<br />
              <span style="color:green;margin-left:10px">
                {{ board.rentInvoice5 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台東店<br />
              <span style="color:green;margin-left:10px">
                {{  board.rentInvoice6 | filter_commaFormate }}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              合作店家<br />
              <span style="color:green;margin-left:10px">
                {{  board.rentInvoice7 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
        </el-col>
        <!-- 第二部分 -->
        <el-col :span="5">
          <el-row>
            <el-card shadow="always"
              style="font-size:20px;margin:0 auto;margin-top:5px;text-align:center;background-color: yellowgreen;">額外銷售<br/>發票金額</el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              MOFUN大本營<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoiceHead | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              西門店<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoice1 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              基隆店<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoice2 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台中門店+高鐵店<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoice3 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台南門店+高鐵店<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoice4 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              高雄門店+高鐵店<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoice5 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台東店<br />
              <span style="color:green;margin-left:10px">
                {{ board.sellInvoice6 | filter_commaFormate }}</span>元
            </el-card>
          </el-row>
        </el-col>
        <!-- 第三部分 -->
        <el-col :span="5">
          <el-row>
            <el-card shadow="always"
              style="font-size:20px;margin:0 auto;margin-top:5px;text-align:center;background-color: yellowgreen;">門市<br />收現數</el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              西門店<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice1 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              基隆店<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice2 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台中店<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice3 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台南店<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice4 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              高雄店<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice5 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              台東店<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice6 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              合作店家<br />
              <span style="color:green;margin-left:10px">
                {{ board.cashInvoice7 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
        </el-col>
        <!-- 第四、第五 -->
        <el-col :span="5">
          <el-row>
            <el-card shadow="always"
              style="font-size:20px;margin:0 auto;margin-top:5px;text-align:center;background-color: yellowgreen;">行動支付<br />收現數</el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              藍新科技<br />
              <span style="color:green;margin-left:10px">
                {{ board.invoiceMoneyType1 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              LINE PAY支付<br />
              <span style="color:green;margin-left:10px">
                {{ board.invoiceMoneyType2 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              ICASH PAY支付<br />
              <span style="color:green;margin-left:10px">
                {{ board.invoiceMoneyType3 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              匯款<br />
              <span style="color:green;margin-left:10px">
                {{ board.invoiceMoneyType5 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="font-size:20px;margin:0 auto;margin-top:5px;text-align:center;background-color: yellowgreen;">訂金<br />增加數</el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              藍新科技<br />
              <span style="color:green;margin-left:10px">
                {{ board.depositMoneyType1 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              LINE PAY支付<br />
              <span style="color:green;margin-left:10px">
                {{ board.depositMoneyType2 | filter_commaFormate }}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              ICASH PAY支付<br />
              <span style="color:green;margin-left:10px">
                {{ board.depositMoneyType3 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              現金<br />
              <span style="color:green;margin-left:10px">
                {{ board.depositMoneyType4 | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
        </el-col>
        <!-- 第六部分 -->
        <el-col :span="4">
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              訂金沖銷<br />
              <span style="color:green;margin-left:10px">
                {{ board.invoiceDeposit | filter_commaFormate}}</span>元(有開發票)
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;" @click.native="downXls('nonInvoiceDeposit')">
              訂金沖銷<br />
              <span style="color:green;margin-left:10px">
                {{ board.nonInvoiceDeposit | filter_commaFormate}}</span>元(未開發票)
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;" @click.native="downXls('nonFinishDepoist')">
              訂金未沖銷<br />
              <span style="color:green;margin-left:10px">
                {{ board.nonFinishDeposit | filter_commaFormate}}</span>元
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;" @click.native="downloadExcel()">
              發票開立<br />
              <span style="color:green;margin-left:10px">
                {{ board.successCount | filter_commaFormate}}</span>筆
            </el-card>
          </el-row>
          <el-row>
            <el-card shadow="always"
              style="margin:0 auto;margin-top:5px;text-align:center;">
              發票作廢<br />
              <span style="color:green;margin-left:10px">
                {{ board.invalidCount | filter_commaFormate}}</span>筆
            </el-card>
          </el-row>
        </el-col>
      </el-row>
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
           <h2>發票查詢</h2>
          </template>
          <el-row :gutter="20" style="margin-top:10px;">
            <el-col :span="6">          
              門市&nbsp;&nbsp;<el-select v-model="qStoreId">
                <el-option 
                  v-for="item in stores"
                  :key="item.code"
                  :label="item.disName"
                  :value="item.code"
                  >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="11">
                <el-input placeholder="會員名稱或帳號或訂單號" v-model="qClientUserDisNameClientOrderId">
                  <template slot="prepend">會員名稱、帳號、訂單號</template>
                </el-input>
            </el-col>
            <el-col :span="5">
                開立結果&nbsp;&nbsp;
              <el-select v-model="qStatus" style="width:120px">
                <el-option  v-for="item in status" :key="item.code" :label="item.disName" :value="item.code" ></el-option>
              </el-select>
            </el-col>
            
          </el-row>
          <el-row :gutter="20" style="margin-top:10px;">
            
            <el-col :span="7">
                <el-input placeholder="發票號碼" v-model="qInvoiceId">
                  <template slot="prepend">發票號碼</template>
                </el-input>
            </el-col>
            <el-col :span="7">
                <el-input placeholder="交易序號" v-model="qMerchantOrderNo">
                  <template slot="prepend">交易序號</template>
                </el-input>
            </el-col>
            <el-col :span="10">
                <el-input placeholder="" v-model="qFinNote">
                  <template slot="prepend">財務備註(關鍵字)</template>
                </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top:10px;">
            <el-col :span="14">          
              發票開立日期&nbsp;&nbsp;
              <el-date-picker v-model="qStartInvoiceDate" type="date" placeholder="開立時間(起)"></el-date-picker> ~
              <el-date-picker v-model="qEndInvoiceDate" type="date" placeholder="開立時間(訖)"></el-date-picker>
            </el-col>
            <el-col :span="5">
                財務標註&nbsp;&nbsp;
              <el-select v-model="qFinStatus" style="width:120px">
                <el-option  v-for="item in finStatus" :key="item.code" :label="item.disName" :value="item.code" ></el-option>
              </el-select>
            </el-col>
            <el-col :span="5">
                發票類型&nbsp;&nbsp;
              <el-select v-model="qType" style="width:120px">
                <el-option  v-for="item in types" :key="item.code" :label="item.disName" :value="item.code" ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top:10px;">
            <!-- <el-col :span="13">          
              訂單付款日期&nbsp;&nbsp;
              <el-date-picker v-model="qStartPayDate" type="date" placeholder="付款時間(起)"></el-date-picker> ~
              <el-date-picker v-model="qEndPayDate" type="date" placeholder="付款時間(訖)"></el-date-picker>
            </el-col> -->
            <el-col :span="24">
              <el-button type="primary" @click="query()">查詢</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
              <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
              <el-button type="primary" @click="showCreateHandwriteFinDia()" >匯入手開發票</el-button>
              <el-button type="primary" @click="showStoreItemFinDia()">開立銷售發票</el-button>
            </el-col>  
          </el-row>
        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="finStatusDisName" width="80"   label="財務標註"></el-table-column>
        <el-table-column   prop="invoiceId"  width="120px" label="發票號碼">  </el-table-column>
        <el-table-column   prop="type" width="80px" label="發票類型">  </el-table-column>
        <el-table-column   prop="invoiceDate"  width="120px" label="發票日期">  </el-table-column>
        <el-table-column   prop="totalAmt" align="right" width="80px" label="發票金額">  </el-table-column>
        <el-table-column   prop="invoiceDetails" width="300px" label="明細" >  </el-table-column>
        <el-table-column   prop="clientUserDisName" width="120px" label="客戶">  </el-table-column>
        <el-table-column   prop="storeDisName" width="120px" label="店家">  </el-table-column>
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="vatNo"  width="120px" label="統編">  </el-table-column>
        <el-table-column   prop="vatTitle" label="抬頭" >  </el-table-column>
        <el-table-column   prop="status"  width="120px" label="開立結果">  </el-table-column>
        <el-table-column   prop="finNoteShort" width="80"   label="財務備註"></el-table-column>

        <el-table-column   fixed="right"    label="操作"    width="310">
          <template slot-scope="scope"  style="text-align:right">
            <el-button @click="invalidInvoice(scope.row)" style="margin-left:10px" type="danger" size="small" v-show="scope.row.status ==='成功' && scope.row.type !=='租借'">發票作廢</el-button>
            <el-button @click="setComfirmModalEvent(true,scope.row)" v-show="!(scope.row.status ==='成功' && scope.row.type !=='租借')" :type="scope.row.status ==='成功' ?'primary': 'danger'" size="small"  :disabled="scope.row.clientOrderId === null || scope.row.clientOrderId ===''">{{scope.row.status ==='成功' ?'查看訂單': '查看補開'}}</el-button>
            <el-button @click="showFinDia(scope.row)"  v-show="$store.getters.userType == '0'"  type="warning" size="small">財務作業</el-button>
            <el-button @click="getRequestInvoiceResultFromNewePay(scope.row)" type="primary" size="small" :disabled="scope.row.type === '手開'">從藍新查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>
  <!-- 明細 -->
  <financialClientOrderDailog
    :comfirmModal="comfirmModal"
    :comfirmModelsContent="comfirmModelsContent"
    :invoiceFunction="showInvoiceBtn"
    @setComfirmModalEvent="setComfirmModalEvent"
    @comfirmModalOkEvent="comfirmModalOkEvent"
    @comfirmModalCancelEvent="comfirmModalCancelEvent">
  </financialClientOrderDailog>
<el-dialog title="財務標註" :visible.sync="diaFinShow" width="400px" :close-on-click-modal="false">
  <el-form ref="form" label-width="80px">
    <el-form-item label="訂單號碼">
      {{finForm.clientOrderId}}
    </el-form-item>
    <el-form-item label="門市" v-if="finForm.type === '手開'">
      <el-select v-model="finForm.storeId" :clearable="false">
        <el-option 
          v-for="item in storesMap"
          :key="item.code"
          :label="item.disName"
          :value="item.code">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="ezPay編號">
      {{finForm.payId}}
    </el-form-item>
    <el-form-item label="發票號碼">
      {{finForm.invoiceId}}
    </el-form-item>
    <el-form-item label="開立日期">
      <el-date-picker :clearable="false"  v-model="finForm.invoiceDate"></el-date-picker>
    </el-form-item>
    <el-form-item label="財務標註">
      <el-select v-model="finForm.finStatus" placeholder="財務標註">
        <el-option  v-for="item in finStatusForm" :key="item.code" :label="item.disName" :value="item.code" ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="財務備註">
      <el-input type="textarea"  v-model="finForm.finNote"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="diaFinShow = false">取 消</el-button>
    <el-button type="primary" @click="confirmUpdateFinStatus()">確 定</el-button>
  </span>
</el-dialog>
<el-dialog title="匯入手開發票" :visible.sync="diaHandwriteFinShow" width="400px" :close-on-click-modal="false">
  <el-form ref="form" label-width="80px">
    <el-form-item label="門市">
      <el-select v-model="handwriteFinForm.storeId" :clearable="false">
        <el-option 
          v-for="item in storesMap"
          :key="item.code"
          :label="item.disName"
          :value="item.code">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="發票號碼">
      <el-input v-model="handwriteFinForm.invoiceId" ></el-input>
    </el-form-item>
    <el-form-item label="受買人">
      <el-input v-model="handwriteFinForm.vatTitle"></el-input>
    </el-form-item>
    <el-form-item label="統一編號">
      <el-input v-model="handwriteFinForm.vatNo"></el-input>
    </el-form-item>
    <el-form-item :label="'品項'+(index+1)" v-for="(i,index) in handwriteFinForm.item" :key="index">
      <el-input v-model="i.itemName" placeholder="品名"></el-input>
      <el-input v-model="i.count" placeholder="數量"></el-input>
      <el-input v-model="i.unit" placeholder="單位"></el-input>
      <el-input v-model="i.price" placeholder="單價"></el-input>
      <el-input v-model="i.amt" placeholder="總價"></el-input>
    </el-form-item>
    <!-- 短期內單張發票單一品項 -->
    <!-- <div style="text-align:right;margin-bottom:10px">
    <el-button @click="addItem()" size="mini" type="primary" >新增品項</el-button>
    </div> -->
    <el-form-item label="小計">
      <el-input v-model="handwriteFinForm.totalAmt"></el-input>
    </el-form-item>
    <el-form-item label="開立日期">
      <el-date-picker :clearable="false"  v-model="handwriteFinForm.invoiceDate"></el-date-picker>
    </el-form-item>
    <el-form-item label="財務標註">
      <el-select v-model="handwriteFinForm.finStatus" placeholder="財務標註">
        <el-option  v-for="item in finStatusForm" :key="item.code" :label="item.disName" :value="item.code" ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="財務備註">
      <el-input type="textarea"  v-model="handwriteFinForm.finNote"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="diaHandwriteFinShow = false">取 消</el-button>
    <el-button type="primary" @click="confirmCreateHandWriteFin()">確 定</el-button>
  </span>
</el-dialog>
<el-dialog title="開立銷售發票" :visible.sync="diaStoreSellItemFinShow" width="80%" :close-on-click-modal="false">
  <el-form ref="form" label-width="120px">
    <el-form-item label="門市">
      <el-select v-model="storeSellItemFinForm.storeId" :clearable="false">
        <el-option 
          v-for="item in storesMap"
          :key="item.code"
          :label="item.disName"
          :value="item.code">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="受買人">
      <el-input v-model="storeSellItemFinForm.vatTitle"></el-input>
    </el-form-item>
    <el-form-item label="付款方式">
      <el-select v-model="storeSellItemFinForm.moneyType" :clearable="false">
        <el-option  key="CASH" label="現金" value="CASH"></el-option>
        <el-option  key="ATM" label="匯款" value="ATM"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="發票類型">
      <el-select v-model="storeSellItemFinForm.invoiceType" :clearable="false">
        <el-option  key="S" label="銷售" value="S"></el-option>      
        <el-option  key="C" label="合作" value="C"></el-option>
        <el-option  key="O" label="渠道" value="O"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="統一編號">
      <el-input v-model="storeSellItemFinForm.vatNo" placeholder="個人發票請留白"></el-input>
    </el-form-item>
    <el-form-item label="載具編號">
      <el-input v-model="storeSellItemFinForm.carrierNum" placeholder="請確認輸入法為英數"></el-input>
    </el-form-item>
    <el-form-item label="電子信箱">
      <el-input v-model="storeSellItemFinForm.email"></el-input>
    </el-form-item>
    <el-form-item :label="'品項'+(index+1)" v-for="(i,index) in storeSellItemFinForm.item" :key="index">
      <el-input v-model="i.itemName" placeholder="品名"></el-input>
      <el-input v-model="i.count" placeholder="數量"></el-input>
      <el-input v-model="i.unit" placeholder="單位"></el-input>
      <el-input v-model="i.price" placeholder="單價"></el-input>
      <el-input v-model="i.amt" placeholder="總價"></el-input>
    </el-form-item>
    <!-- 短期內單張發票單一品項 -->
    <!-- <div style="text-align:right;margin-bottom:10px">
    <el-button @click="addItem()" size="mini" type="primary" >新增品項</el-button>
    </div> -->
    <el-form-item label="小計">
      <el-input v-model="storeSellItemFinForm.totalAmt"></el-input>
    </el-form-item>
    <el-form-item label="備註">
      <el-input type="textarea"  v-model="storeSellItemFinForm.finNote"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="diaStoreSellItemFinShow = false">取 消</el-button>
    <el-button type="primary" @click="confirmCreateStoreSellItemFin()">確 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import financialClientOrderDailog from '@/components/financialClientOrder/financialClientOrderDialog'
import * as moment from "moment/moment";
export default {
  components:{
    financialClientOrderDailog
  },
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qClientUserDisNameClientOrderId:'',qStoreId:'',qCreateDate: null,qStatus: '',qInvoiceId: '', qFinStatus: '', qFinNote: '',qInvoiceDate: '',
      qStartInvoiceDate:null,qEndInvoiceDate:null,qStartPayDate: null,qEndPayDate: null,qMerchantOrderNo: '',qType:'',
      tableData:[],
      historyFlow:[],
      clientOrderTrans: [], // 支付紀錄
      dialogFormVisible: false,
      finForm: {invoiceId: '', clientOrderId: '', finStatus: '', finNote: '',invoiceDate:null,storeId: ''},
      handwriteFinForm: {invoiceId: '', vatNo:'',vatTitle:'', totalAmt:'',invoiceDate:'',finStatus:'',finNote:'',storeId:'',
        item: [{itemName:'',count:'',unit:'',price:'',amt:''}]},
      storeSellItemFinForm: {vatNo:'',vatTitle:'', totalAmt:'',email:'',storeId:'',finNote:'',carrierNum:'',moneyType:'CASH',invoiceType:'S',
        item: [{itemName:'',count:'',unit:'',price:'',amt:''}]},
      form: {
        deposit:0,
        note: "",     
      },
      formLabelWidth: '120px',
      stores: [],
      storesMap: [],
      status: [{code:'',disName:'全部'},{code:'SUCCESS',disName:'成功'},{code:'INVALID',disName:'作廢'}, {code:'ERROR',disName:'失敗'},{code:'NONE',disName:'未開立發票'}],
      finStatus: [{code:'',disName:'全部'},{code:'OK',disName:'已處理'}, {code:'ERROR',disName:'異常'}, {code:'WAIT',disName:'等待處理'}, {code:'INVALID',disName:'作廢'}, {code:'NULL',disName:'未標註'}],
      finStatusForm: [{code:'OK',disName:'已處理'}, {code:'ERROR',disName:'異常'}, {code:'WAIT',disName:'等待處理'}, {code:'INVALID',disName:'作廢'}],
      types:[{code:'',disName:'全部'},{code:'R',disName:'租借'},{code:'S',disName:'銷售'},{code:'H',disName:'手開'},
             {code:'C',disName:'合作'},{code:'O',disName:'渠道'},{code:'T',disName:'罰單'}],
      selectStores: [],
      flows:[],
      activeName: '1', // 搜尋手風琴預設
      assignRowBankNo: '',
      assignRowReadFlag: 'Y',
      fullscreenLoading: false,
      isHandle: false,
      comfirmModal: false,
      comfirmModelsContent: null,
      showInvoiceBtn: false,
      diaFinShow: false,
      diaHandwriteFinShow: false,
      diaStoreSellItemFinShow: false,
      board:{
        rentInvoiceHead:0, rentInvoice1:0, rentInvoice2:0, rentInvoice3:0, rentInvoice4:0, rentInvoice5:0, rentInvoice6:0, rentInvoice7:0,
        sellInvoiceHead:0, sellInvoice1:0, sellInvoice2:0, sellInvoice3:0, sellInvoice4:0, sellInvoice5:0, sellInvoice6:0, sellInvoice7:0,
        cashInvoice1:0, cashInvoice2:0, cashInvoice3:0, cashInvoice4:0, cashInvoice5:0, cashInvoice6:0, cashInvoice7:0,
        invoiceMoneyType1:0, invoiceMoneyType2:0, invoiceMoneyType3:0, invoiceMoneyType4:0,invoiceMoneyType5:0,
        depositMoneyType1:0, depositMoneyType2:0, depositMoneyType3:0, depositMoneyType4:0, depositMoneyType5:0,
        invoiceDeposit:0, nonInvoiceDeposit:0, nonFinishDeposit:0, successCount:0, invalidCount:0,
        }
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        this.qEndInvoiceDate = moment().toDate()
        this.qStartInvoiceDate = moment().subtract(3,'days')
        res.data.authStores.forEach(e => {
          this.storesMap.push(e)
          this.stores.push(e)
        });
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  name: 'FinancialInvoiceQuery',
  methods :{
    formattedPrice(num){
      let comma=/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g
      return num.toString().replace(comma, ',')
    },
    confirmUpdateFinStatus () {
      this.$confirm('發票' + this.finForm.invoiceId +'是否要更新?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let params = {
            storeId: this.finForm.storeId,
            invoiceId: this.finForm.invoiceId,
            finStatus: this.finForm.finStatus,
            finNote: this.finForm.finNote,
            invoiceDate: moment(this.finForm.invoiceDate).format('YYYYMMDD')
          }
          this.$axios.post('../api/financialInvoiceQuery/updateFinStatus', params).then((res) => {
            this.fullscreenLoading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
            if (res.data.rs === 'success') {
              this.diaFinShow = false
              this.query()
            }
          }).catch(()=>{this.fullscreenLoading = false})
        })
    },
    confirmCreateHandWriteFin() {
      if(this.handwriteFinForm.invoiceId === '') {
        this.$alert("請輸入發票號碼", "注意", { confirmButtonText: "確定" });
            return;
      }
      if(this.handwriteFinForm.vatTitle === '') {
        this.$alert("請輸入受買人", "注意", { confirmButtonText: "確定" });
            return;
      }
      let items = this.handwriteFinForm.item
      try {
        items.forEach( (item,index)=>{
          if(item.itemName === '') {
            throw new Error("請輸入品項"+(index+1)+"品名")
          }
          if(item.count === '') {
            throw new Error("請輸入品項"+(index+1)+"數量")
          }
          if(item.unit === '') {
            throw new Error("請輸入品項"+(index+1)+"單位")
          }
          if(item.price === '') {
            throw new Error("請輸入品項"+(index+1)+"單價")
          }
          if(item.amt === '') {
            throw new Error("請輸入品項"+(index+1)+"總價")
          }
  
        })
      } catch (e) {
        this.$alert(e, "注意", { confirmButtonText: "確定" });
        return
      }
      if(this.handwriteFinForm.totalAmt === '') {
        this.$alert("請輸入小計", "注意", { confirmButtonText: "確定" });
            return;
      }
      if(this.handwriteFinForm.invoiceDate === '') {
        this.$alert("請輸入發票日期", "注意", { confirmButtonText: "確定" });
            return;
      }
      
      this.handwriteFinForm.invoiceDate = moment(this.handwriteFinForm.invoiceDate).format('YYYYMMDD')
      this.$confirm('是否新增' + this.handwriteFinForm.invoiceId +'發票?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          this.$axios.post('../api/financialInvoiceQuery/createHandwrittenInvoice', this.handwriteFinForm).then((res) => {
            this.fullscreenLoading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
            if (res.data.rs === 'success') {
              this.diaHandwriteFinShow = false
              this.query()
            }
          }).catch(()=>{this.fullscreenLoading = false})
        })
    },
    confirmCreateStoreSellItemFin(){
      if(this.storeSellItemFinForm.storeId === '') {
        this.$alert("請選擇門市", "注意", { confirmButtonText: "確定" });
            return;
      }
      if(this.storeSellItemFinForm.vatTitle === '') {
        this.$alert("請輸入受買人", "注意", { confirmButtonText: "確定" });
            return;
      }
      if(this.storeSellItemFinForm.vatNo !== '' && this.storeSellItemFinForm.carrierNum !== ''){
        this.$alert("開立統編不可使用載具", "注意", { confirmButtonText: "確定" });
            return;
      }
      if (this.storeSellItemFinForm.carrierNum !== '') {
        if (!this.storeSellItemFinForm.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert("載具編號錯誤", "注意", { confirmButtonText: "確定" });
          return
        }
      }
      let items = this.storeSellItemFinForm.item
      try {
        items.forEach( (item,index)=>{
          if(item.itemName === '') {
            throw new Error("請輸入品項"+(index+1)+"品名")
          }
          if(item.count === '') {
            throw new Error("請輸入品項"+(index+1)+"數量")
          }
          if(item.unit === '') {
            throw new Error("請輸入品項"+(index+1)+"單位")
          }
          if(item.price === '') {
            throw new Error("請輸入品項"+(index+1)+"單價")
          }
          if(item.amt === '') {
            throw new Error("請輸入品項"+(index+1)+"總價")
          }
  
        })
      } catch (e) {
        this.$alert(e, "注意", { confirmButtonText: "確定" });
        return
      }
      if(this.storeSellItemFinForm.totalAmt === '') {
        this.$alert("請輸入小計", "注意", { confirmButtonText: "確定" });
            return;
      }
      if(this.storeSellItemFinForm.email === '') {
        this.$alert("請輸入電子信箱", "注意", { confirmButtonText: "確定" });
            return;
      }
      
      this.$confirm('是否開立'+this.storeSellItemFinForm.totalAmt+'元發票?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          this.$axios.post('../api/financialInvoiceQuery/createStoreItemInvoice', this.storeSellItemFinForm).then((res) => {
            this.fullscreenLoading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
            if (res.data.rs === 'success') {
              this.diaStoreSellItemFinShow = false
              this.query()
            }
          }).catch(()=>{this.fullscreenLoading = false})
        })
    },
    showFinDia (invoice) {
      this.finForm = Object.assign({}, {invoiceId :'', finStatus: '', finNote: '',invoiceDate:null,storeId: ''})
      this.finForm.invoiceId = invoice.invoiceId
      this.finForm.storeId = invoice.storeId
      this.finForm.clientOrderId = invoice.clientOrderId
      this.finForm.type = invoice.type
      this.finForm.finStatus = invoice.finStatus
      this.finForm.finNote = invoice.finNote
      this.finForm.invoiceDate = moment(invoice.invoiceDate)
      this.finForm.payId = invoice.payId
      this.diaFinShow = true
    },
    query(){
      let _qInvoiceDate = this.qInvoiceDate === null ? '' : moment(this.qInvoiceDate).format('YYYYMMDD');
      let _qStartInvoiceDate = this.qStartInvoiceDate === null ? '' : moment(this.qStartInvoiceDate).format('YYYYMMDD');
      let _qEndInvoiceDate = this.qEndInvoiceDate === null ? '' : moment(this.qEndInvoiceDate).format('YYYYMMDD');
      let _qStartPayDate = this.qStartPayDate === null ? '' : moment(this.qStartPayDate).format('YYYYMMDD');
      let _qEndPayDate = this.qEndPayDate === null ? '' : moment(this.qEndPayDate).format('YYYYMMDD');
      this.fullscreenLoading = true
      let qForm= {page:this.pageCurrent,
       qStoreId:this.qStoreId,
       qClientUserDisNameClientOrderId:this.qClientUserDisNameClientOrderId,
       qInvoiceDate:_qInvoiceDate,
       qStartInvoiceDate:_qStartInvoiceDate,
       qEndInvoiceDate:_qEndInvoiceDate,
       qStartPayDate:_qStartPayDate,
       qEndPayDate:_qEndPayDate,
       qStatus: this.qStatus,
       qInvoiceId: this.qInvoiceId,
       qFinStatus: this.qFinStatus,
       qType: this.qType,
       qMerchantOrderNo: this.qMerchantOrderNo
       }
      this.$axios.get('../api/financialInvoiceQuery/getInvoices', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(clientOrder => {
            if(clientOrder.clientUserDisName === null || clientOrder.clientUserDisName === ''){
              clientOrder.clientUserDisName = clientOrder.vatTitle
            }
            if( clientOrder.type === 'H'){
              clientOrder.type = '手開'
            } else if (clientOrder.type === 'R'){
              clientOrder.type = '租借'
            } else if (clientOrder.type ==='S') {
              clientOrder.type = '銷售'
            }else if (clientOrder.type ==='C') {
              clientOrder.type = '合作'
            }else if (clientOrder.type ==='O') {
              clientOrder.type = '渠道'
            }
            
              if (clientOrder.vatNo === ''){
                clientOrder.vatTitle = ''
              }
              clientOrder.invoiceDate = this.$getDateTime_('date', clientOrder.invoiceDate+'000000')
              // clientOrder.clientOrderId = clientOrder.payId
              if (clientOrder.status === 'SUCCESS') {
                clientOrder.status = '成功'
              } else if (clientOrder.status === 'INVALID') {
                clientOrder.status = '已作廢'
              } else {
                clientOrder.status = '失敗(' + clientOrder.status + ')'
              }
              clientOrder.finStatusDisName = this.$finStatus(this.finStatusForm, clientOrder.finStatus)
              clientOrder.depositPayMethod = clientOrder.deposit == 0 ? '--':this.$payTypeDisName(clientOrder.depositPayMethod)
              clientOrder.endPayMethod = this.$payTypeDisName(clientOrder.endPayMethod)
              clientOrder.finNoteShort = clientOrder.finNote !== null && clientOrder.finNote.length > 3 ? (clientOrder.finNote.substring(0, 3) + '...') : clientOrder.finNote
              if(clientOrder.storeDisName === null || clientOrder.storeDisName === ''){
                clientOrder.storeDisName = clientOrder.itlStoreDisName
              }
              this.tableData.push(clientOrder)
            })
          this.pageTotal=res.data.pageTotal
          this.getBoardData()
      }).catch((e)=>{
        console.log(e)
        this.fullscreenLoading = false
        this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' })
      });  
    },
    getBoardData(){
      if (this.qStartInvoiceDate == null || this.qEndInvoiceDate == null) {
        return
      }
      let qForm= {
       qStoreId:this.qStoreId,
       qSDate:moment(this.qStartInvoiceDate).format('YYYYMMDD'),
       qEDate:moment(this.qEndInvoiceDate).format('YYYYMMDD')
       }
      this.$axios.get('../api/financialInvoiceQuery/getInvoiceBoardsData',{params: qForm}).then(res => {
        this.board = res.data.data
        console.log(res.data.data)
      })
    },
    getRequestInvoiceResultFromNewePay(clientOrder){
      let qForm= { payId: clientOrder.payId, price: clientOrder.totalAmt }
      this.fullscreenLoading = true
      this.$axios
        .get('../api/financialInvoiceQuery/getRequestInvoiceResultFromNewePay', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          let content = '代碼:'+ res.data.status + '訊息:' + res.data.message
          if (res.data.invoiceId !== undefined) {
            content += ' 發票號碼:' + res.data.invoiceId +'金額' + res.data.totalAmt + '元'
          }
          this.$alert(content, '結果', { confirmButtonText: '確定' })
          this.query()
      }).catch(()=>{
        this.fullscreenLoading = false
        this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' })
      });  
    },
    reset(){
      this.qStoreId=this.stores[0].code
      this.qClientUserDisNameClientOrderId=''
      this.qMerchantOrderNo=''
      this.qInvoiceId= ''
      this.qDisName = null
      this.qInvoiceDate = null
      this.qEndInvoiceDate = moment().toDate()
      this.qStartInvoiceDate = moment().subtract(3,'days')
      this.qStartPayDate = null
      this.qEndPayDate = null
      // this.query()
    },
    setComfirmModalEvent (bol,clientOrder) {
      if(clientOrder !== undefined && clientOrder !== null) {
        this.showInvoiceBtn = (clientOrder.status !== '成功')
        let param = {
          clientOrderId: clientOrder.clientOrderId,
            rowId: clientOrder.rowId
        }
        this.$axios
        .get('../api/financialInvoiceQuery/getInvoice', {params:param})
        .then((res) => {
        this.comfirmModelsContent = res.data.data
        this.comfirmModelsContent.invoiceId = clientOrder.invoiceId
        this.comfirmModelsContent.createDate = this.$getDateTime_('date', this.comfirmModelsContent.createDate)
        // this.comfirmModelsContent.clientOrderId = this.comfirmModelsContent.payId
        this.comfirmModelsContent.status = this.comfirmModelsContent.status === 'SUCCESS' ? '成功' : '失敗(' + this.comfirmModelsContent.status + ')'
        this.comfirmModelsContent.finStatusDisName = this.$finStatus(this.finStatus, this.comfirmModelsContent.finStatus)
        this.comfirmModelsContent.depositPayMethod = this.comfirmModelsContent.deposit == 0 ? '--':this.$payTypeDisName(this.comfirmModelsContent.depositPayMethod)
        this.comfirmModelsContent.endPayMethod = this.$payTypeDisName(this.comfirmModelsContent.endPayMethod)
        this.comfirmModelsContent.finNoteShort = this.comfirmModelsContent.finNote !== null && this.comfirmModelsContent.finNote.length > 3 ? (this.comfirmModelsContent.finNote.substring(0, 3) + '...') : this.comfirmModelsContent.finNote
        this.getClientRentDateLongPayStageList(this.form.clientOrderId)
        
        })
      }
      this.comfirmModal = bol
    },
    comfirmModalOkEvent (bol) {
      this.comfirmModal = bol
    },
    comfirmModalCancelEvent (bol) {
      this.comfirmModal = bol
    },
    showCreateHandwriteFinDia () {
      this.diaHandwriteFinShow = true
      this.handwriteFinForm =Object.assign( {invoiceId: '', vatNo:'',vatTitle:'', totalAmt:'',invoiceDate:'',finStatus:'',finNote:'',storeId:'',
      item: [{itemName:'',count:'',unit:'',price:'',amt:''}]})
      this.handwriteFinForm.storeId = this.storesMap[0].code
      this.storesMap.forEach((store) =>{
        if (store.disName == '車輛停放區_mofun'){
          this.handwriteFinForm.storeId = store.code
        }
      })
    },
    showStoreItemFinDia (){
      this.diaStoreSellItemFinShow = true
      this.storeSellItemFinForm = Object.assign( { vatNo:'',vatTitle:'', totalAmt:'',email:'',storeId:'',carrierNum:'',moneyType:'CASH',invoiceType:'S',
        item: [{itemName:'',count:'',unit:'',price:'',amt:''}]})
      this.storeSellItemFinForm.storeId = this.storesMap[0].code
    },
    addItem() {
      this.handwriteFinForm.item.push({itemName:'',count:'',unit:'',price:'',amt:''})
      this.storeSellItemFinForm.item.push({itemName:'',count:'',unit:'',price:'',amt:''})
    },
    downloadExcel () {
      if (this.qStartInvoiceDate === null || this.qEndInvoiceDate === null) {
        if(this.qStartPayDate === null || this.qEndPayDate === null){
          this.$alert('請填寫任意日期區間','結果',{confirmButtonText : '確定'})
          return

        }
      }
      if ( this.qStartInvoiceDate == null | this.qEndInvoiceDate == null ) {
        if ( this.qStartInvoiceDate != null | this.qEndInvoiceDate != null ) {
          this.$alert('請正確填寫發票開立日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( this.qStartPayDate == null | this.qEndPayDate == null ) {
        if ( this.qStartPayDate != null | this.qEndPayDate != null ) {
          this.$alert('請正確填寫訂單付款日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( moment(this.qEndInvoiceDate) - moment(this.qStartInvoiceDate) > 90*86400000 ) { // 86400000 一天
        this.$alert('發票開立日期區間不可超過90天', '結果', { confirmButtonText: '確定' });
        return
      }
      if ( moment(this.qEndPayDate) - moment(this.qStartPayDate) > 90*86400000 ) { // 86400000 一天
        this.$alert('訂單付款日期區間不可超過90天', '結果', { confirmButtonText: '確定' });
        return
      }

      let _qStartInvoiceDate = this.qStartInvoiceDate === null ? '' : moment(this.qStartInvoiceDate).format('YYYYMMDD');
      let _qEndInvoiceDate = this.qEndInvoiceDate === null ? '' : moment(this.qEndInvoiceDate).format('YYYYMMDD');
      let _qStartPayDate = this.qStartPayDate === null ? '' : moment(this.qStartPayDate).format('YYYYMMDD');
      let _qEndPayDate = this.qEndPayDate === null ? '' : moment(this.qEndPayDate).format('YYYYMMDD');
      
      window.open('../api/financialInvoiceQuery/downloadExcel?qStoreId='+this.qStoreId
      +'&qClientUserDisNameClientOrderId='+this.qClientUserDisNameClientOrderId
      +'&qMerchantOrderNo='+this.qMerchantOrderNo
      +'&qStartInvoiceDate='+_qStartInvoiceDate
      +'&qEndInvoiceDate='+_qEndInvoiceDate
      +'&qStartPayDate='+_qStartPayDate
      +'&qEndPayDate='+_qEndPayDate
      +'&qStatus='+this.qStatus
      +'&qInvoiceId='+this.qInvoiceId
      +'&qFinStatus='+this.qFinStatus
      +'&qType='+this.qType)
    },
    downXls(type) {
      if (this.qSDate === "" || this.qEDate === "" || this.qSDate === null || this.qEDate === null) {
        this.$alert("日期區間不可為空", "結果", { confirmButtonText: "確定" });
        return;
      }
      if (moment(this.qSDate) - moment(this.qEDate) > 90 * 86400000) {
        this.$alert("日期區間不可超過90天", "結果", { confirmButtonText: "確定" });
        return;
      }
      let _qSDate = moment(this.qSDate).isValid() ? moment(this.qSDate).format("YYYYMMDD") : "";
      let _qEDate = moment(this.qEDate).isValid() ? moment(this.qEDate).format("YYYYMMDD") : "";
      let url = "../api/clientOrder/export/excel?" + "&qStoreId=" + this.qStoreId;

      if (type === "nonInvoiceDeposit") {
        url += "&qPayDateStart=" + _qSDate + "&qPayDateEnd=" + _qEDate + '&qRentDateType=短租&downExcelType=nonInvoiceDeposit&downExcelTypeExtend='+this.board.nonInvoiceDepositOrders;
      } else if (type === "nonFinishDepoist") {
        url += "&qRentDateType=短租&downExcelType=nonFinishDepoist";
      }
      window.open(url);
    },
    invalidInvoice (invoice) {
      this.$confirm('確定要作廢'+invoice.invoiceId+'發票嗎？','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        let pa = {
            invoiceId: invoice.invoiceId
          };
          this.loading = true
          this.$axios.post('../api/financialInvoiceQuery/invalidInvoice', pa).then((res) => {
            this.loading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            this.query()
      }).catch(() => {
            this.loading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        })
      },
      
  }
}
</script>
